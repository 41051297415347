import {
  ApiOutlined,
  AppstoreOutlined,
  ArrowRightOutlined,
  BellOutlined,
  BookOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleFilled,
  CloseCircleOutlined,
  CloseOutlined,
  ContactsOutlined,
  CopyOutlined,
  CreditCardOutlined,
  DeleteOutlined,
  DollarCircleOutlined,
  DownloadOutlined,
  DownOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  ExclamationCircleOutlined,
  ExportOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  FileDoneOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  FilterOutlined,
  GlobalOutlined,
  ImportOutlined,
  InfoCircleOutlined,
  LineChartOutlined,
  LoadingOutlined,
  LockOutlined,
  LoginOutlined,
  LogoutOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MoreOutlined,
  NotificationOutlined,
  PercentageOutlined,
  PieChartOutlined,
  PlusOutlined,
  PoweroffOutlined,
  QuestionCircleOutlined,
  ReloadOutlined,
  RetweetOutlined,
  RobotOutlined,
  SafetyOutlined,
  SaveOutlined,
  SearchOutlined,
  SelectOutlined,
  SettingOutlined,
  SyncOutlined,
  TagOutlined,
  TeamOutlined,
  UnlockOutlined,
  UploadOutlined,
  UpOutlined,
  UpSquareOutlined,
  UserOutlined,
  WarningFilled,
  InboxOutlined,
  HistoryOutlined,
  BlockOutlined,
  PlayCircleFilled,
  PlayCircleTwoTone,
  FlagOutlined,
} from "@ant-design/icons";

export const ICFlagOutlined = ({ className = "", ...props }) => {
  return <FlagOutlined className={className} {...props} />;
};

export const ICHistoryOutlined = ({ className = "", ...props }) => {
  return <HistoryOutlined className={className} {...props} />;
};

export const ICPlayCircleFilled = ({ className = "", ...props }) => {
  return <PlayCircleFilled className={className} {...props} />;
};

export const ICPlayCircleTwoTone = ({ className = "", ...props }) => {
  return <PlayCircleTwoTone className={className} {...props} />;
};

export const ICDownloadOutlined = ({ className = "", ...props }) => {
  return <DownloadOutlined className={className} {...props} />;
};

export const ICInboxOutlined = ({ className = "", ...props }) => {
  return <InboxOutlined className={className} {...props} />;
};

export const ICBlockOutlined = ({ className = "", ...props }) => {
  return <BlockOutlined className={className} {...props} />;
};

export const ICSyncOutlined = ({ className = "", ...props }) => {
  return <SyncOutlined className={className} {...props} />;
};

export const ICPercentageOutlined = ({ className = "", ...props }) => {
  return <PercentageOutlined className={className} {...props} />;
};

export const ICNotificationOutlined = ({ className = "", ...props }) => {
  return <NotificationOutlined className={className} {...props} />;
};

export const ICFilterOutlined = ({ className = "", ...props }) => {
  return <FilterOutlined className={className} {...props} />;
};

export const ICCloseCircleOutlined = ({ className = "", ...props }) => {
  return <CloseCircleOutlined className={className} {...props} />;
};

export const ICCopyOutlined = ({ className = "", ...props }) => {
  return <CopyOutlined className={className} {...props} />;
};

export const ICLoadingOutlined = ({ className = "", ...props }) => {
  return <LoadingOutlined className={className} {...props} />;
};

export const ICSelectOutlined = ({ className = "", ...props }) => {
  return <SelectOutlined className={className} {...props} />;
};

export const ICCloseCircleFilled = ({ className = "", ...props }) => {
  return <CloseCircleFilled className={className} {...props} />;
};

export const ICWarningFilled = ({ className = "", ...props }) => {
  return <WarningFilled className={className} {...props} />;
};

export const ICCheckCircleFilled = ({ className = "", ...props }) => {
  return <CheckCircleFilled className={className} {...props} />;
};

export const ICPieChartOutlined = ({ className = "", ...props }) => {
  return <PieChartOutlined className={className} {...props} />;
};

export const ICEyeOutlined = ({ className = "", ...props }) => {
  return <EyeOutlined className={className} {...props} />;
};

export const ICEyeInvisibleOutlined = ({ className = "", ...props }) => {
  return <EyeInvisibleOutlined className={className} {...props} />;
};

export const ICImportOutlined = ({ className = "", ...props }) => {
  return <ImportOutlined className={className} {...props} />;
};

export const ICGlobalOutlined = ({ className = "", ...props }) => {
  return <GlobalOutlined className={className} {...props} />;
};

export const ICBellOutlined = ({ className = "", ...props }) => {
  return <BellOutlined className={className} {...props} />;
};

export const ICMenuFoldOutlined = ({ className = "", ...props }) => {
  return <MenuFoldOutlined className={className} {...props} />;
};

export const ICFileDoneOutlined = ({ className = "", ...props }) => {
  return <FileDoneOutlined className={className} {...props} />;
};

export const ICMoreOutlined = ({ className = "", ...props }) => {
  return <MoreOutlined className={className} {...props} />;
};

export const ICFilePdfOutlined = ({ className = "", ...props }) => {
  return <FilePdfOutlined className={className} {...props} />;
};

export const ICBookOutlined = ({ className = "", ...props }) => {
  return <BookOutlined className={className} {...props} />;
};

export const ICLogoutOutlined = ({ className = "", ...props }) => {
  return <LogoutOutlined className={className} {...props} />;
};

export const ICSettingOutlined = ({ className = "", ...props }) => {
  return <SettingOutlined className={className} {...props} />;
};

export const ICDollarCircleOutlined = ({ className = "", ...props }) => {
  return <DollarCircleOutlined className={className} {...props} />;
};

export const ICContactsOutlined = ({ className = "", ...props }) => {
  return <ContactsOutlined className={className} {...props} />;
};

export const ICDownOutlined = ({ className = "", ...props }) => {
  return <DownOutlined className={className} {...props} />;
};

export const ICLockOutlined = ({ className = "", ...props }) => {
  return <LockOutlined className={className} {...props} />;
};

export const ICLoginOutlined = ({ className = "", ...props }) => {
  return <LoginOutlined className={className} {...props} />;
};

export const ICSearchOutlined = ({ className = "", ...props }) => {
  return <SearchOutlined className={className} {...props} />;
};

export const ICAppstoreOutlined = ({ className = "", ...props }) => {
  return <AppstoreOutlined className={className} {...props} />;
};

export const ICLineChartOutlined = ({ className = "", ...props }) => {
  return <LineChartOutlined className={className} {...props} />;
};

export const ICRetweetOutlined = ({ className = "", ...props }) => {
  return <RetweetOutlined className={className} {...props} />;
};

export const ICRobotOutlined = ({ className = "", ...props }) => {
  return <RobotOutlined className={className} {...props} />;
};

export const ICTeamOutlined = ({ className = "", ...props }) => {
  return <TeamOutlined className={className} {...props} />;
};

export const ICQuestionCircleOutlined = ({ className = "", ...props }) => {
  return <QuestionCircleOutlined className={className} {...props} />;
};

export const ICCheckOutlined = ({ className = "", ...props }) => {
  return <CheckOutlined className={className} {...props} />;
};

export const ICTagOutlined = ({ className = "", ...props }) => {
  return <TagOutlined className={className} {...props} />;
};

export const ICPoweroffOutlined = ({ className = "", rotate }) => {
  return <PoweroffOutlined className={className} rotate={rotate} />;
};

export const ICInfoCircleOutlined = ({ className = "", ...props }) => {
  return <InfoCircleOutlined className={className} {...props} />;
};

export const ICInfoCircleOutlinedTextSecondary = () => {
  return <InfoCircleOutlined className="text-secondary" />;
};

export const ICCaretUpOutlined = ({ className = "", ...props }) => {
  return <CaretUpOutlined className={className} {...props} />;
};

export const ICCaretDownOutlined = ({ className = "", ...props }) => {
  return <CaretDownOutlined className={className} {...props} />;
};

export const ICExclamationCircleFilled = ({ className = "", ...props }) => {
  return <ExclamationCircleFilled className={className} {...props} />;
};

export const ICUploadOutlined = ({ className = "", ...props }) => {
  return <UploadOutlined className={className} {...props} />;
};

export const ICCheckCircleOutlined = ({ className = "", ...props }) => {
  return <CheckCircleOutlined className={className} {...props} />;
};

export const ICPlusOutlined = ({ className = "", ...props }) => {
  return <PlusOutlined className={className} {...props} />;
};

export const ICDeleteOutlined = ({ className = "", ...props }) => {
  return <DeleteOutlined className={className} {...props} />;
};

export const ICArrowRightOutlined = () => {
  return <ArrowRightOutlined />;
};

export const ICEditOutlined = ({ className = "", ...props }) => {
  return <EditOutlined className={className} {...props} />;
};

export const ICCreditCardOutlined = ({ className = "", ...props }) => {
  return <CreditCardOutlined className={className} {...props} />;
};

export const ICFileImageOutlined = ({ className = "", ...props }) => {
  return <FileImageOutlined className={className} {...props} />;
};

export const ICMailOutlined = ({ className = "", ...props }) => {
  return <MailOutlined className={className} {...props} />;
};

export const ICSafetyOutlined = ({ className = "", ...props }) => {
  return <SafetyOutlined className={className} {...props} />;
};

export const ICUnlockOutlined = ({ className = "", ...props }) => {
  return <UnlockOutlined className={className} {...props} />;
};

export const ICUserOutlined = ({ className = "", ...props }) => {
  return <UserOutlined className={className} {...props} />;
};

export const ICExclamationCircleOutlined = ({ className = "", ...props }) => {
  return <ExclamationCircleOutlined className={className} {...props} />;
};

export const ICUpSquareOutlined = ({ className = "", rotate, ...props }) => {
  return <UpSquareOutlined className={className} rotate={rotate} {...props} />;
};

export const ICCloseOutlined = ({ ClassName, ...props }) => {
  return <CloseOutlined className={ClassName} {...props} />;
};

export const ICExportOutlined = ({ ClassName, ...props }) => {
  return <ExportOutlined className={ClassName} {...props} />;
};

export const ICSaveOutlined = ({ ClassName, ...props }) => {
  return <SaveOutlined className={ClassName} {...props} />;
};

export const ICReloadOutlined = ({ ClassName, ...props }) => {
  return <ReloadOutlined className={ClassName} {...props} />;
};

export const ICApiOutlined = ({ ClassName, ...props }) => {
  return <ApiOutlined className={ClassName} {...props} />;
};

export const ICUpOutlined = ({ className = "", rotate, ...props }) => {
  return <UpOutlined className={className} rotate={rotate} {...props} />;
};
