import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setManageUsers } from "../../redux/slices/manageUsers";
import { getDataFromDB } from "supabase";

const useGetManageUsers = () => {
  const dispatch = useDispatch();
  const action = setManageUsers;
  const manageData = useSelector(({ manageUsers }) => manageUsers.value);

  const doSet = useCallback(
    (data) => dispatch(action(data)),
    [dispatch, action]
  );

  useEffect(() => {
    const getmanageUsers = async () => {
      const { data } = await getDataFromDB({
        from: "profiles",
        select: "*",
        order_name: { ascending: true },
      });
      if (!manageData) doSet(data);
    };
    if (!manageData) getmanageUsers();
  }, [manageData, doSet]);
};

export default useGetManageUsers;
