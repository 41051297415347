import DashboardHeader from "components/dashboard-header/DashboardHeader";
import useDocumentTitle from "utils/useDocumentTitle";
import { Card, Row, Col, Flex } from "antd";
import { FilterComponent, RadioTable } from "components/Components";
import { useCallback, useEffect, useState } from "react";
import { CardMyTemplate, ListMyCard } from "components/Card/templatesCard";
import { getDataFromDB, signInPublicURL } from "supabase";
import { useSelector } from "react-redux";
import useGetTemplateCategory from "utils/hooks/useGetTemplateCategory";

const MyTemplate = ({ userData }) => {
  useGetTemplateCategory();
  const CategoryData = useSelector(
    ({ templateCategory }) => templateCategory.value
  );
  useDocumentTitle("My Template");
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [dataTemplate, setDataTemplate] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [inputValue, setInputValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPerPage, setPerPage] = useState("20");
  const [currentCategory, setCurrentCategory] = useState("");
  const [radioValue, setRadioValue] = useState("gallery");
  const [publicUrls, setPublicUrls] = useState({});

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const MyTemplateList = useCallback(async () => {
    setShowSkeleton(true);
    const responseData = await getDataFromDB({
      from: "userTemplate",
      select: "*",
      eq: "userId",
      value: userData.id,
      limit: currentPerPage,
      offset: (currentPage - 1) * currentPerPage,
      search: searchValue,
      category: currentCategory,
      order: { ascending: false },
    });
    setDataTemplate(responseData);
    setShowSkeleton(false);
  }, [userData.id, currentPerPage, currentPage, searchValue, currentCategory]);

  useEffect(() => {
    MyTemplateList();
  }, [MyTemplateList]);

  const handleSearch = () => {
    setSearchValue(inputValue);
    setCurrentPage(1);
  };

  const changeSearchQuery = (e) => {
    setInputValue(e.target.value);
  };

  const changePerPage = (limit) => {
    setPerPage(limit);
    setCurrentPage(1);
  };

  const changeCategory = (categories) => {
    setCurrentCategory(categories);
    setCurrentPage(1);
  };

  const recordRenderState = dataTemplate?.data;
  const recordLengthState = dataTemplate?.data
    ? dataTemplate?.data.length === 0
    : null;
  const recordStatusCode = dataTemplate?.status;
  const totalCards = recordLengthState;
  const listCount = dataTemplate?.data ? dataTemplate?.data.length : null;

  useEffect(() => {
    if (recordRenderState && recordRenderState.length > 0) {
      const fetchPublicUrls = async () => {
        const urls = {};

        for (const card of recordRenderState) {
          const url = await signInPublicURL({
            from: "template-images",
            file: card.screenshot,
          });

          if (!url.endsWith("/null")) {
            urls[card.id] = url;
          }
        }
        setPublicUrls(urls);
      };
      fetchPublicUrls();
    }
  }, [recordRenderState]);

  const propsFilter = {
    handleSearch,
    changeSearchQuery,
    changePerPage,
    changeCategory,
  };

  const propsValue = {
    inputValue,
    searchValue,
    currentPage,
    currentCategory,
    currentPerPage,
    CategoryData,
    userData,
    dataTemplate,
    recordRenderState,
    recordStatusCode,
    totalCards,
    listCount,
    publicUrls,
  };

  return (
    <>
      <DashboardHeader
        title="My Templates"
        userData={userData}
        callbackList={MyTemplateList}
      />
      <Row className="px-6 py-3">
        <Col span={24}>
          <Flex vertical gap={24}>
            <FilterComponent
              propsFilter={propsFilter}
              propsValue={propsValue}
            />
            <Card
              title="Email Template"
              extra={
                <RadioTable
                  setRadioValue={setRadioValue}
                  radioValue={radioValue}
                  propsValue={propsValue}
                />
              }
              className="card-Mytemplate"
            >
              {radioValue === "gallery" ? (
                <CardMyTemplate
                  ListTemplate={dataTemplate}
                  showSkeleton={showSkeleton}
                  width={width}
                  propsFilter={propsFilter}
                  propsValue={propsValue}
                  callbackList={MyTemplateList}
                />
              ) : (
                <ListMyCard
                  ListTemplate={dataTemplate}
                  showSkeleton={showSkeleton}
                  width={width}
                  propsFilter={propsFilter}
                  propsValue={propsValue}
                  callbackList={MyTemplateList}
                />
              )}
            </Card>
          </Flex>
        </Col>
      </Row>
    </>
  );
};

export default MyTemplate;
