import { useEffect } from "react";
import { Outlet } from "react-router-dom";

export const CheckLogin = () => {
  useEffect(() => {
    window.location.href = "/templates";
  }, []);

  return <Outlet />;
};

export const CheckLoginManage = (userData) => {
  useEffect(() => {
    if (!userData.user) {
      window.location.href = "manage/login";
    }
  }, [userData.user]);

  return <Outlet />;
};
