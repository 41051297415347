import { Flex, Input, Typography, Select, Button } from "antd";
import AntButton from "components/AntButton";
import { RightOutlined } from "@ant-design/icons";

const { Text } = Typography;

export const TemplateDetail = ({
  newData,
  setInputName,
  setSelectedCategory,
  prev,
  selectedCategory,
  categoryOptions,
  CategoryTemplate,
  handleSaveData,
  loadingSave,
}) => {
  return (
    <Flex vertical align="center" className="mt-6">
      <div className="max-w-348">
        <Text>Template Name</Text>
        <Input
          placeholder="Your template name"
          className="mt-1"
          onChange={(e) => setInputName(e.target.value)}
        />
        <Flex vertical className="mt-6">
          <Text>Template Category</Text>
          <Select
            defaultValue=""
            onChange={(data) => setSelectedCategory(data)}
            {...(newData && {
              value: !selectedCategory ? newData.category : selectedCategory,
            })}
            className="mt-1"
            options={[
              { value: "", label: "Choose category" },
              ...categoryOptions,
            ]}
            disabled={!CategoryTemplate}
          />
        </Flex>
        <AntButton
          type="primary"
          htmlType="submit"
          onClick={handleSaveData}
          block
          loading={loadingSave}
          children={
            <>
              <RightOutlined /> Edit Template
            </>
          }
          className="mt-9"
        />
        <Button
          type="link"
          className="mt-3 text-success w-full"
          onClick={() => prev()}
        >
          Go Back
        </Button>
      </div>
    </Flex>
  );
};
