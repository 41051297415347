import { Suspense } from "react";
import Routing from "./Routing";
import { ConfigProvider } from "antd";
import Spinner from "./components/Spinner/Spinner";
import useGetUser from "utils/hooks/useGetUser";
import { useSelector } from "react-redux";
import { getSessionToken, getCookieToken } from "utils/helpers";

function App() {
  useGetUser();
  const user = useSelector(({ userData }) => userData.value);
  const token = getSessionToken() || getCookieToken();

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#0d9f3f",
          colorInfo: "#0d9f3f",
          borderRadius: 4,
        },
      }}
    >
      <Suspense fallback={<Spinner full />}>
        <Routing userData={user} token={token} />
      </Suspense>
    </ConfigProvider>
  );
}

export default App;
