import { Form, Input, Modal } from "antd";
import {
  regexPassword,
  requiredRules,
  validateRepeatPassword,
} from "utils/helpers";
import ModalSuccess from "./ModalSuccess";
import { updateUser } from "supabase";
import { useState } from "react";
import ModalWarning from "./ModalWarning";

export const ModalChangePassword = ({
  title,
  open,
  close,
  form,
  refreshUser,
}) => {
  const [disableBtn, setDisableBtn] = useState(true);

  const handleValuesChange = (_, allValues) => {
    const isPasswordValid = regexPassword.test(allValues.password);
    const isPasswordSame = allValues.password === allValues.confirm_password;

    if (!isPasswordValid || !isPasswordSame) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  };

  const handleSaveChanges = async () => {
    const { data, error } = await updateUser({
      password: form.getFieldValue("password"),
    });
    if (data) {
      ModalSuccess({
        title: "Password changed successfully",
        customMessage:
          "Password akun anda telah berhasil diupdate pada sistem.",
        width: 500,
        type: "primary",
        onOk: () => {
          close();
          refreshUser();
        },
      });
    }
    if (error) {
      ModalWarning({
        title: "Failed to change password",
        customMessage:
          "Password account anda gagal diupdate. Terdapat kendala pada sistem.",
        width: 500,
        type: "primary",
        onOk: () => close(),
      });
    }
  };

  return (
    <Modal
      centered
      title={title}
      open={open}
      onCancel={close}
      onOk={handleSaveChanges}
      okButtonProps={{
        disabled: disableBtn,
        type: disableBtn ? "default" : "primary",
      }}
    >
      <Form layout="vertical" form={form} onValuesChange={handleValuesChange}>
        <Form.Item
          label="New Password"
          name="password"
          className="hide-padding my-4"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            requiredRules,
            {
              pattern: regexPassword,
              message:
                "Password harus mengandung 8 karakter dengan kombinasi huruf kapital, huruf kecil, angka dan simbol",
            },
          ]}
        >
          <Input.Password placeholder="Strong Password" />
        </Form.Item>
        <Form.Item
          label="Re-type Password"
          name="confirm_password"
          rules={[
            {
              required: true,
              message: "Password tidak sesuai",
            },
            validateRepeatPassword,
          ]}
        >
          <Input.Password placeholder="Re-type strong password" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
