import { Button, Dropdown, Flex } from "antd";
import {
  ICCheckOutlined,
  ICCloseOutlined,
  ICEditOutlined,
  ICExportOutlined,
  ICImportOutlined,
  ICLockOutlined,
  ICPlusOutlined,
  ICSaveOutlined,
} from "./list/ListIcon";
import { useNavigate } from "react-router-dom";
import { HandleExitBuilder } from "utils/handleFunction";
import { DownloadOutlined, EllipsisOutlined } from "@ant-design/icons";
import { ActionMenu } from "./Action";
import { exportTemplateZip } from "utils/helpers";

const AntButton = ({
  type,
  children,
  htmlType,
  disabled,
  onClick,
  block,
  className,
  style,
  icon,
  href,
  loading,
}) => {
  return (
    <>
      {type === "primary" && (
        <Button
          type={type}
          onClick={onClick}
          block={block}
          htmlType={htmlType}
          disabled={disabled}
          className={`btn ${className}`}
          style={style}
          icon={icon}
          loading={loading}
        >
          {children}
        </Button>
      )}
      {type === "text" && (
        <Button
          type={type}
          className={`w-full ${className}`}
          onClick={onClick}
          style={style}
          href={href}
          icon={icon}
          disabled={disabled}
        >
          {children}
        </Button>
      )}
    </>
  );
};

export default AntButton;

export const BtnCreate = ({ className, mainState }) => {
  const navigate = useNavigate();
  return (
    <Button
      icon={<ICPlusOutlined />}
      type="primary"
      className={className}
      onClick={() => {
        if (mainState) {
          navigate("/manage/templates/create");
        } else {
          navigate("create");
        }
      }}
    >
      Create New
    </Button>
  );
};

export const BtnExportImport = ({
  className,
  Import,
  setOpenModalImport,
  type,
  disable,
}) => {
  return (
    <Button
      icon={Import ? <ICImportOutlined /> : <ICExportOutlined />}
      className={className}
      onClick={() => setOpenModalImport(true)}
      type={type}
      disabled={disable}
    >
      {Import ? (type ? "Import Template" : "Import") : "Export"}
    </Button>
  );
};

export const BtnSave = ({
  className,
  title,
  handleSaveTemplate,
  saveTemplate,
  loadingState,
  finishState,
  disable,
}) => {
  return (
    <Button
      icon={finishState ? <ICCheckOutlined /> : <ICSaveOutlined />}
      type="primary"
      className={className}
      onClick={() =>
        handleSaveTemplate ? handleSaveTemplate() : saveTemplate()
      }
      loading={loadingState}
      disabled={disable}
    >
      {title ? title : "Save Template"}
    </Button>
  );
};

export const BtnCancel = ({
  CreatePageEditor,
  openModalDelete,
  setOpenModalDelete,
  EditPage,
  TemplateID,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <Button
        icon={<ICCloseOutlined />}
        {...(!(CreatePageEditor || EditPage) && { className: "btn-cancel" })}
        onClick={() =>
          CreatePageEditor || EditPage ? setOpenModalDelete(true) : navigate(-1)
        }
      >
        {!(CreatePageEditor || EditPage) ? "Cancel" : "Exit"}
      </Button>
      <HandleExitBuilder
        openModalDelete={openModalDelete}
        setOpenModalDelete={setOpenModalDelete}
        TemplateID={TemplateID}
      />
    </>
  );
};

export const BtnGroupDetail = ({ data, CategoryData, userData, disable }) => {
  const navigate = useNavigate();
  return (
    <Flex gap={8}>
      <Button
        icon={<ICEditOutlined />}
        onClick={() => {
          if (data.id) {
            navigate(`/manage/templates/edit/${data.id}`, { state: data });
          }
          if (data.uid) {
            navigate(`/manage/gallery/edit/${data.uid}`, { state: data });
          }
        }}
        {...(disable && data.uid && { disabled: disable })}
      >
        Edit Template
      </Button>
      <Button
        icon={<DownloadOutlined />}
        type="primary"
        onClick={() => exportTemplateZip(data)}
      >
        Export
      </Button>
      <Dropdown
        trigger={["click"]}
        placement="bottomRight"
        {...(disable && data.uid && { disabled: disable })}
        overlay={() => (
          <ActionMenu
            data={data}
            detailPage={true}
            CategoryData={CategoryData}
            userData={userData}
          />
        )}
      >
        <Button>
          More
          <EllipsisOutlined />
        </Button>
      </Dropdown>
    </Flex>
  );
};

export const BtnGroupProfile = ({
  handleOpenModalUsers,
  handleOpenModalChanges,
}) => {
  return (
    <Flex gap={8}>
      <Button
        icon={<ICEditOutlined />}
        type="primary"
        block
        onClick={handleOpenModalUsers}
      >
        Edit Profile
      </Button>
      <Button icon={<ICLockOutlined />} block onClick={handleOpenModalChanges}>
        Change Password
      </Button>
    </Flex>
  );
};
