import magnifierEmpty from "assets/images/magnifier.svg";
import ServerError from "assets/images/Galat-Data-API.svg";
import NotAvail from "assets/images/not-avail.svg";
import { BtnCreate } from "components/AntButton";

export const emptyTemplate = ({ totalCards, propsValue }) => {
  const { searchValue } = propsValue;
  return (
    <>
      <img
        src={
          totalCards && searchValue !== ""
            ? magnifierEmpty
            : totalCards
            ? NotAvail
            : ServerError
        }
        alt="Template Empty"
        {...(totalCards && searchValue !== ""
          ? { className: "mb-0 pt-3" }
          : { className: "mb-1 pt-3" })}
        style={{ width: 258, height: 200 }}
      />
      <p
        className={`${
          totalCards || (totalCards && searchValue !== "") ? "mt-1" : "mt-3"
        } mb-1 text-primary font-sm font-medium text-base`}
      >
        {totalCards && searchValue !== ""
          ? "Template tidak ditemukan"
          : totalCards
          ? "Template belum tersedia"
          : "Gagal memuat data"}
      </p>
      <p
        className={`${
          totalCards && searchValue === "" ? "mb-4" : "mb-0"
        } mt-0 text-secondary text-center text-sm`}
        style={{ maxWidth: 400 }}
      >
        {totalCards && searchValue !== ""
          ? "Email template tersebut tidak ditemukan, silakan dapat cari dengan kata kunci lainnya"
          : totalCards
          ? "Anda belum melakukan memiliki email template. Silakan dapat membuat template email anda untuk mengirim email."
          : "Sistem gagal memuat data. Harap dapat muat ulang atau refresh browser yang anda gunakan."}
      </p>
      {totalCards && searchValue === "" && <BtnCreate />}
    </>
  );
};

export const emptyMainTemplate = ({ totalCards }) => {
  return (
    <>
      <img
        src={totalCards ? NotAvail : ServerError}
        alt="Template Empty"
        className="mb-1 pt-3"
        style={{ width: 258, height: 200 }}
      />
      <p
        className={`${
          totalCards ? "mt-1" : "mt-3"
        } mb-1 text-primary font-sm font-medium text-base`}
      >
        {totalCards ? "Template belum tersedia" : "Gagal memuat data"}
      </p>
      <p
        className="mb-4 mt-0 text-secondary text-center text-sm"
        style={{ maxWidth: 400 }}
      >
        {totalCards
          ? "Anda belum melakukan memiliki email template. Silakan dapat membuat template email anda untuk mengirim email."
          : "Sistem gagal memuat data. Harap dapat muat ulang atau refresh browser yang anda gunakan."}
      </p>
      <BtnCreate mainState={true} />
    </>
  );
};

export const emptyTemplateDetail = () => {
  return (
    <>
      <img
        src={ServerError}
        alt="Template Empty"
        className="mb-0 pt-3"
        style={{ width: 258, height: 200 }}
      />
      <p className="mt-1 mb-1 text-primary font-sm font-medium text-base">
        Gagal memuat data
      </p>
      <p
        className="mb-0 mt-0 text-secondary text-center text-sm"
        style={{ maxWidth: 400 }}
      >
        Sistem gagal memuat data. Harap dapat muat ulang atau refresh browser
        yang anda gunakan.
      </p>
    </>
  );
};
