import { Card, Col, Flex, Row, Steps, Typography } from "antd";
import DashboardHeader from "components/dashboard-header/DashboardHeader";
import { useEffect, useState } from "react";
import { ChooseTemplate } from "./ChooseTemplate";
import { TemplateDetail } from "./TemplateDetail";
import useGetTemplateCategory from "utils/hooks/useGetTemplateCategory";
import { useSelector } from "react-redux";
import { insertDataToDB, updateDataToDB, uploadToBucket } from "supabase";
import { useNavigate } from "react-router-dom";
import useDocumentTitle from "utils/useDocumentTitle";

const { Text } = Typography;

export const AddMyTemplate = ({ userData }) => {
  useDocumentTitle("Create Template");
  useGetTemplateCategory();
  const navigate = useNavigate();
  const [current, setCurrent] = useState(0);
  const [newData, setNewData] = useState(false);
  const [inputName, setInputName] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loadingSave, setLoadingSave] = useState(false);
  const CategoryTemplate = useSelector(
    ({ templateCategory }) => templateCategory.value
  );
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [detailTemplate, setDetailTemplate] = useState(null);
  const TemplateJSON = detailTemplate?.data?.record?.json;
  const TemplateScreenshoot = detailTemplate?.data?.record?.screenshot;

  useEffect(() => {
    if (Array.isArray(CategoryTemplate)) {
      const category = CategoryTemplate.map((category) => ({
        value: category.id,
        label: category.name,
      }));
      setCategoryOptions(category);
    }
  }, [CategoryTemplate]);

  useEffect(() => {
    const getDetailTemplate = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BE_BASE_URL}/aktiva-templates/${newData.uid}?edit=true`,
        {
          headers: {
            "X-Api-Key": `${process.env.REACT_APP_API_KEY}`,
          },
        }
      );
      const { data } = await response.json();
      setDetailTemplate({ data });
    };
    if (newData && newData.uid) {
      getDetailTemplate();
    }
  }, [newData]);

  const next = (data) => {
    if (data) {
      setNewData(data);
    }
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const handleSaveData = async () => {
    setLoadingSave(true);

    const imageUrl = TemplateScreenshoot;
    let imageFile = null;

    if (imageUrl) {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      imageFile = new File(
        [blob],
        `${!inputName ? newData.name : inputName}.jpg`,
        { type: "image/jpeg" }
      );
    }

    const { data, status, error } = await insertDataToDB({
      from: "userTemplate",
      insert: {
        userId: userData.id,
        name: !inputName ? newData.name : inputName,
        templateCategory: !selectedCategory
          ? newData.category === "Transactional"
            ? 1
            : newData.category === "Promotion"
            ? 2
            : newData.category === "Newsletter"
            ? 3
            : newData.category === "Invitation"
            ? 4
            : 5
          : selectedCategory,
        ...(newData && {
          contentJSON: TemplateJSON,
          storage: "template-images",
        }),
      },
    });

    if (status === 201 && data[0]) {
      if (imageFile) {
        const savedId = data[0].id.toString();
        const path = `${savedId}/${data[0].name}`;

        const { data: uploadData, error: uploadError } = await uploadToBucket({
          from: "template-images",
          path: path,
          file: imageFile,
        });

        if (uploadData && !uploadError) {
          const { data: updatedData, error: updateError } =
            await updateDataToDB({
              from: "userTemplate",
              update: {
                screenshot: path,
              },
              eq: "id",
              value: savedId,
            });

          if (updatedData && !updateError) {
            setTimeout(() => {
              setLoadingSave(false);
              navigate(`${data[0].id}`, { state: data[0] });
            }, 1000);
          } else {
            console.log(updateError);
            setLoadingSave(false);
          }
        } else {
          console.log(uploadError);
          setLoadingSave(false);
        }
      } else {
        setTimeout(() => {
          setLoadingSave(false);
          navigate(`${data[0].id}`, { state: data[0] });
        }, 1000);
      }
    } else {
      console.log(error);
      setLoadingSave(false);
    }
  };

  const props = {
    next,
    prev,
    newData,
    inputName,
    setInputName,
    selectedCategory,
    setSelectedCategory,
    categoryOptions,
    CategoryTemplate,
    handleSaveData,
    loadingSave,
  };

  const steps = [
    {
      step: 1,
      title: "Choose Template",
      content: <ChooseTemplate {...props} />,
    },
    {
      step: 2,
      title: "Template Details",
      content: <TemplateDetail {...props} />,
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <>
      <DashboardHeader title="Create New Template" />
      <Row className="px-6 py-3">
        <Col span={24}>
          <Card>
            <div className="box-step mx-auto">
              <div className="container-step">
                <Steps current={current} items={items} />
              </div>
            </div>
            <Flex vertical gap={4} className="mb-2">
              <Text className="font-medium text-3-xl">
                {current === 0 ? "Choose Template" : "Template Details"}
              </Text>
              <Text className="text-secondary">
                {current === 0
                  ? "Pilih template email yang ingin digunakan"
                  : "Lengkapi informasi detail template email anda"}
              </Text>
            </Flex>
            <div className="mx-auto">
              <div className="steps-content">{steps[current]?.content}</div>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};
