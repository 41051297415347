import { Modal, Typography } from "antd";
import ModalSuccess from "./ModalSuccess";
import { deleteDataFromDB } from "supabase";
import ModalWarning from "./ModalWarning";

const { Text } = Typography;

export const ModalDeleteMyTemplate = ({
  setOpenModalDelete,
  openModalDelete,
  data,
  callbackList,
  detailPage,
}) => {
  const handleDeleteTemplate = async () => {
    const { status } = await deleteDataFromDB({
      from: "userTemplate",
      eq: "id",
      value: data.id,
    });
    if (status === 204) {
      ModalSuccess({
        title: "Email template deleted successfully",
        width: 416,
        customMessage: (
          <span>
            Email template {data.name} successfully deleted from your account.
          </span>
        ),
        type: "primary",
        onOk: async () => {
          setOpenModalDelete(false);
          if (!detailPage) {
            await callbackList();
          } else {
            window.location.href = "/manage/templates";
          }
        },
      });
    } else {
      ModalWarning({
        title: "Failed delete email template",
        width: 416,
        customMessage:
          "Something wrong in the system. Please try again or contact our Support Team.",
        type: "primary",
        okText: "Ok",
        onOk: () => setOpenModalDelete(false),
      });
    }
  };

  return (
    <Modal
      centered
      title="Delete email template"
      open={openModalDelete}
      width={540}
      okText="Yes delete"
      onOk={handleDeleteTemplate}
      cancelText="Cancel"
      onCancel={() => setOpenModalDelete(false)}
      okButtonProps={{ type: "danger" }}
    >
      <Text>
        Are you sure want delete email template with name{" "}
        <span className="font-bold">{data.name}</span>? <br />
        <br />
        After template deleted, email template will no longer in your account
        and can’t be undone.
      </Text>
    </Modal>
  );
};
