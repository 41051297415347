import { Spin } from "antd";
import { ICLoadingOutlined } from "../list/ListIcon";
import "./spinner.less";

const Spinner = ({ loading, full = false, children }) => {
  const styleSpinner = (
    <ICLoadingOutlined className="spinner-size m-auto" spin />
  );
  return (
    <Spin
      spinning={loading}
      indicator={styleSpinner}
      className={`max-h-full ${
        full ? "flex justify-center relative spinner-height" : ""
      }`}
      delay={{ duration: 3000 }}
    >
      {children}
    </Spin>
  );
};
export default Spinner;
