import { Button, Col, Flex, Row, Select } from "antd";
import { ICPlusOutlined } from "components/list/ListIcon";

const { Option } = Select;

export const TableTitle = ({
  title,
  description,
  setOpenModalUsers,
  screen,
  userRole
}) => {
  const MediumScreen = screen.md;
  const SmallScreen = screen.sm;
  const XsmallScreen = screen.xs;
  return (
    <Row className="items-center">
      <Col span={12}>
        <div className="title-text">
          <h5 className="text-base font-medium mb-1 mt-0">{title}</h5>
          <p className="text-sm text-secondary mb-0 mt-0">{description}</p>
        </div>
      </Col>
      <Col span={12}>
        <Flex gap={8} justify="flex-end">
          <Select defaultValue="10" disabled>
            <Option value="10">10 per page</Option>
            <Option value="25">25 per page</Option>
            <Option value="50">50 per page</Option>
            <Option value="100">100 per page</Option>
          </Select>
          <Button
            type="primary"
            icon={<ICPlusOutlined />}
            onClick={() => setOpenModalUsers(true)}
            disabled={userRole}
          >
            {!MediumScreen && (SmallScreen || XsmallScreen) ? "" : "Add New"}
          </Button>
        </Flex>
      </Col>
    </Row>
  );
};
