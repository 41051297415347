import { Flex, Input, Modal, Select, Typography } from "antd";
import ModalSuccess from "./ModalSuccess";
import { useEffect } from "react";
import ModalWarning from "./ModalWarning";
import { useState } from "react";
import { insertDataToDB } from "supabase";

export const ModalDuplicate = ({
  openModalDuplicate,
  setOpenModalDuplicate,
  data,
  CategoryData,
  userData,
  callbackList,
}) => {
  const [nameValue, setNameValue] = useState(
    data ? `Copy of ${data.name}` : ""
  );
  const [categoryValue, setCategoryValue] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);

  useEffect(() => {
    if (Array.isArray(CategoryData)) {
      const category = CategoryData.map((category) => ({
        value: category.id,
        label: category.name,
      }));
      setCategoryOptions(category);
    }
  }, [CategoryData]);

  const handleDuplicate = async () => {
    const { status } = await insertDataToDB({
      from: "userTemplate",
      insert: {
        userId: userData.id,
        name: nameValue,
        templateCategory: categoryValue,
        ...(data && {
          contentJSON: data.contentJSON,
          storage: data.storage,
          screenshot: data.screenshot,
        }),
      },
    });
    if (status === 201) {
      ModalSuccess({
        title: "Template duplicated successfully",
        customMessage: `Email template ${nameValue} successfully duplicated in your account.`,
        width: 416,
        type: "primary",
        okText: "Ok",
        onOk: async () => {
          setOpenModalDuplicate(false);
          await callbackList();
          setNameValue(`Copy of ${data.name}`);
          setCategoryValue("");
        },
      });
    } else {
      ModalWarning({
        title: "Failed duplicate email template",
        customMessage:
          "Something wrong in the system. Please try again or contact our Support Team.",
        width: 416,
        okText: "Ok",
        type: "primary",
        onOk: () => {
          setOpenModalDuplicate(false);
          setNameValue(`Copy of ${data.name}`);
          setCategoryValue("");
        },
      });
    }
  };

  return (
    <Modal
      title="Duplicate Email Template"
      centered
      okText="Duplicate Now"
      open={openModalDuplicate}
      okButtonProps={{
        disabled: nameValue === "" || categoryValue === "",
        type: nameValue === "" || categoryValue === "" ? "default" : "primary",
      }}
      onCancel={() => setOpenModalDuplicate(false)}
      width={572}
      onOk={handleDuplicate}
    >
      <Typography.Text>
        Duplicate email template {data.name} and create the new version
      </Typography.Text>
      <Flex vertical className="pt-4">
        <Typography.Text>Template Name</Typography.Text>
        <Input
          placeholder="Your template name"
          className="mt-1"
          value={nameValue}
          onChange={(e) => setNameValue(e.target.value)}
        />
        <Typography.Text className="pt-4">Email Category</Typography.Text>
        <Select
          onChange={(data) => setCategoryValue(data)}
          defaultValue=""
          className="mt-1"
          options={[
            { value: "", label: "Choose category" },
            ...categoryOptions,
          ]}
          disabled={!CategoryData}
        />
      </Flex>
    </Modal>
  );
};
