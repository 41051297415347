import { Layout, Row, Col, Flex } from "antd";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

const { Footer } = Layout;

export const FooterPage = () => {
  return (
    <Footer
      className="footer-text font-footer p-0"
      style={{ backgroundColor: "#1F1F1F" }}
    >
      <Row>
        <Col
          xl={10}
          lg={10}
          md={10}
          sm={24}
          xs={24}
          className="py-3 pl-3 pr-10 font-inter"
        >
          <p className="font-medium text-base mt-0 mb-0 pb-2">Tentang Aktiva</p>
          <p className="text-sm mt-0 mb-0" style={{ color: "#BFBFBF" }}>
            PT. Aktiva Kreasi Investama merupakan perusahaan dibidang SaaS
            (Software as a Services) dengan fokus layanan dibidang Email. Motto
            perusahaan adalah Quality, Respect, Integrity dan goalnya adalah
            menjadi platform email yang mudah digunakan dan dapat diintegrasikan
            dengan aplikasi internal customer
          </p>
        </Col>
        <Col
          xl={7}
          lg={7}
          md={7}
          sm={24}
          xs={24}
          className="py-3 px-3 font-inter"
        >
          <p className="font-medium text-base mt-0 mb-0 pb-2">Solusi Aktiva</p>
          <Flex vertical>
            <Link
              to="https://www.aktiva.co.id/mail-campaign/"
              className="footer-text text-sm p-0 inactive-link"
            >
              Mail Campaign
            </Link>
            <Link
              to="https://www.aktiva.co.id/transaksional-email/"
              className="footer-text text-sm p-0 inactive-link"
            >
              Transaksional Email
            </Link>
            <Link
              to="https://www.aktiva.co.id/self-service-password-management/"
              className="footer-text text-sm p-0 inactive-link"
            >
              Self-service Password Management
            </Link>
            <Link
              to="https://www.aktiva.co.id/microsoft-365/"
              className="footer-text text-sm p-0 inactive-link"
            >
              Subscription Microsoft 365
            </Link>
            <Link
              to="https://www.aktiva.co.id/subscription-zextras-carbonio/"
              className="footer-text text-sm p-0 inactive-link"
            >
              Subscription Zextras Carbonio
            </Link>
            <Link
              to="https://www.aktiva.co.id/ssl-certificate/"
              className="footer-text text-sm p-0 inactive-link"
            >
              SSL Certificate
            </Link>
          </Flex>
        </Col>
        <Col
          xl={7}
          lg={7}
          md={7}
          sm={24}
          xs={24}
          className="py-3 px-3 font-inter"
        >
          <Flex vertical>
            <p className="font-medium text-base mt-0 mb-0 pb-2">Kontak Kami</p>
            <span
              className="text-sm leading-7 p-0"
              style={{ color: "#BFBFBF" }}
            >
              <FontAwesomeIcon
                icon={icon({
                  name: "envelope",
                  family: "classic",
                  style: "regular",
                })}
              />
              <span style={{ paddingLeft: 5 }}>sales@aktiva.co.id</span>
            </span>
            <span
              className="text-sm leading-7 p-0"
              style={{ color: "#BFBFBF" }}
            >
              <FontAwesomeIcon
                icon={icon({
                  name: "phone",
                  family: "classic",
                  style: "solid",
                })}
              />
              <span style={{ paddingLeft: 5 }}>021 8267 8811</span>
            </span>
            <span
              className="text-sm leading-7 p-0"
              style={{ color: "#BFBFBF" }}
            >
              <FontAwesomeIcon
                icon={icon({
                  name: "building",
                  family: "classic",
                  style: "regular",
                })}
              />
              <span style={{ paddingLeft: 8 }}>Our Office</span>
            </span>
            <p className="text-sm mt-0 mb-0" style={{ color: "#BFBFBF" }}>
              Ruko Premier Serenity Lt. 2 Blok J No. 12
            </p>
            <p className="text-sm mt-0 mb-0" style={{ color: "#BFBFBF" }}>
              Jl. KH Agus Salim No. 40E
            </p>
            <p className="text-sm mt-0 mb-0" style={{ color: "#BFBFBF" }}>
              Bekasi Jaya, Bekasi Timur – 17112
            </p>
            <p className="text-sm mt-0 mb-0" style={{ color: "#BFBFBF" }}>
              Kota Bekasi – Indonesia
            </p>
          </Flex>
        </Col>
      </Row>
    </Footer>
  );
};
