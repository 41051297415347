import { Dropdown, Menu, Divider, Flex, Collapse } from "antd";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Link } from "react-router-dom";

const { Panel } = Collapse;

const itemsSolusi = [
  {
    label: (
      <a
        href="https://www.aktiva.co.id/email-validator/"
        className="text-navbar"
      >
        Aktiva Email Validator
      </a>
    ),
    key: "1",
    style: {
      padding: "16px 8px 16px 28px",
      borderBottom: "0.5px solid #F7F7F7",
    },
  },
  {
    label: (
      <a href="https://www.aktiva.co.id/mail-campaign/" className="text-navbar">
        Aktiva Mail Campaign
      </a>
    ),
    key: "2",
    style: {
      padding: "16px 8px 16px 28px",
      borderBottom: "0.5px solid #F7F7F7",
    },
  },
  {
    label: (
      <a
        href="https://www.aktiva.co.id/self-service-password-management/"
        className="text-navbar"
      >
        Aktiva Self-service Password Management
      </a>
    ),
    key: "3",
    style: {
      padding: "16px 8px 16px 28px",
      borderBottom: "0.5px solid #F7F7F7",
    },
  },
  {
    label: (
      <a
        href="https://www.aktiva.co.id/transaksional-email/"
        className="text-navbar"
      >
        Aktiva Transaksional Email
      </a>
    ),
    key: "4",
    style: {
      padding: "16px 8px 16px 28px",
      borderBottom: "0.5px solid #F7F7F7",
    },
  },
  {
    label: (
      <a
        href="https://www.aktiva.co.id/ssl-certificate/"
        className="text-navbar"
      >
        Aktiva SSL Certificate
      </a>
    ),
    key: "5",
    style: {
      padding: "16px 8px 16px 28px",
      borderBottom: "0.5px solid #F7F7F7",
    },
  },
  {
    label: (
      <a href="https://www.aktiva.co.id/microsoft-365/" className="text-navbar">
        Subscription Microsoft 365
      </a>
    ),
    key: "6",
    style: {
      padding: "16px 8px 16px 28px",
      borderBottom: "0.5px solid #F7F7F7",
    },
  },
  {
    label: (
      <a
        href="https://www.aktiva.co.id/subscription-zextras-carbonio/"
        className="text-navbar"
      >
        Subscription Zextras Carbonio
      </a>
    ),
    key: "7",
    style: {
      padding: "16px 8px 16px 28px",
    },
  },
];

const itemsPerusahaan = [
  {
    label: (
      <a href="https://www.aktiva.co.id/karir/" className="text-navbar">
        Karir
      </a>
    ),
    key: "1",
    style: {
      padding: "16px 8px 16px 28px",
      borderBottom: "0.5px solid #F7F7F7",
    },
  },
  {
    label: (
      <a
        href="https://www.aktiva.co.id/tentang-aktiva/"
        className="text-navbar"
      >
        Tentang Aktiva
      </a>
    ),
    key: "2",
    style: {
      padding: "16px 8px 16px 28px",
    },
  },
];

export const itemsTemplate = [
  {
    key: "1",
    label: (
      <a
        href="https://www.aktiva.co.id/"
        className="text-navbar font-medium text-sm p-2 family-sans"
      >
        Home
      </a>
    ),
    style: {
      padding: "16px 20px",
    },
  },
  {
    key: "2",
    label: (
      <a
        href="https://www.aktiva.co.id/solusi-kami/"
        className="text-navbar font-medium text-sm p-2 family-sans"
      >
        <Dropdown
          overlay={<Menu items={itemsSolusi} style={{ maxWidth: 217 }} />}
        >
          <span>
            Solusi Kami{" "}
            <FontAwesomeIcon
              icon={icon({
                name: "angle-down",
                family: "classic",
                style: "solid",
              })}
            />
          </span>
        </Dropdown>
      </a>
    ),
    style: {
      padding: "16px 20px",
    },
  },
  {
    key: "3",
    label: (
      <a href="#" className="text-success font-medium text-sm p-2 family-sans">
        Template
      </a>
    ),
    style: {
      padding: "16px 20px",
    },
  },
  {
    key: "4",
    label: (
      <a
        href="https://www.aktiva.co.id/blog/"
        className="text-navbar font-medium text-sm p-2 family-sans"
      >
        Blog
      </a>
    ),
    style: {
      padding: "16px 20px",
    },
  },
  {
    key: "5",
    label: (
      <a href="#" className="text-navbar font-medium text-sm p-2 family-sans">
        <Dropdown
          overlay={<Menu items={itemsPerusahaan} style={{ width: 217 }} />}
        >
          <span>
            Perusahaan{" "}
            <FontAwesomeIcon
              icon={icon({
                name: "angle-down",
                family: "classic",
                style: "solid",
              })}
            />
          </span>
        </Dropdown>
      </a>
    ),
    style: {
      padding: "16px 20px",
    },
  },
  {
    key: "6",
    label: (
      <a
        href="https://www.aktiva.co.id/kontak/"
        className="text-navbar font-medium text-sm p-2 family-sans"
      >
        Kontak
      </a>
    ),
    style: {
      padding: "16px 20px",
    },
  },
];

export const itemsTemplateSmall = (stopPropagation) => [
  {
    key: "1",
    label: (
      <a
        href="https://www.aktiva.co.id/"
        className="text-navbar font-medium text-sm family-sans"
      >
        Home
      </a>
    ),
    style: {
      padding: "16px 20px",
    },
  },
  {
    label: <Divider className="my-0" />,
  },
  {
    key: "2",
    label: (
      <Collapse ghost className="collapse-navbar" onClick={stopPropagation}>
        <Panel
          showArrow={false}
          header={
            <a
              href="https://www.aktiva.co.id/solusi-kami/"
              className="text-navbar text-sm family-sans"
            >
              Solusi Kami
            </a>
          }
          extra={
            <FontAwesomeIcon
              icon={icon({
                name: "angle-down",
                family: "classic",
                style: "solid",
              })}
              style={{ marginTop: 4 }}
            />
          }
          key="solusi"
          onClick={stopPropagation}
        >
          {itemsSolusi.map((item) => (
            <div
              key={item.key}
              style={{
                padding: "16px 6px",
                borderBottom: "0.5px solid #F7F7F7",
              }}
            >
              <a
                href={item.href}
                className="text-navbar font-medium text-sm family-sans"
              >
                {item.label}
              </a>
            </div>
          ))}
        </Panel>
      </Collapse>
    ),
    style: {
      padding: "16px 20px",
    },
  },
  {
    label: <Divider className="my-0" />,
  },
  {
    key: "3",
    label: (
      <a
        href="#"
        className="font-medium text-sm family-sans"
        style={{ color: "#0D9F3F" }}
      >
        Template
      </a>
    ),
    style: {
      padding: "16px 20px",
    },
  },
  {
    label: <Divider className="my-0" />,
  },
  {
    key: "4",
    label: (
      <a
        href="https://www.aktiva.co.id/blog/"
        className="text-navbar font-medium text-sm family-sans"
      >
        Blog
      </a>
    ),
    style: {
      padding: "16px 20px",
    },
  },
  {
    label: <Divider className="my-0" />,
  },
  {
    key: "5",
    label: (
      <Collapse ghost className="collapse-navbar" onClick={stopPropagation}>
        <Panel
          header={
            <span>
              <Flex justify="space-between">
                Perusahaan{" "}
                <FontAwesomeIcon
                  icon={icon({
                    name: "angle-down",
                    family: "classic",
                    style: "solid",
                  })}
                  style={{ marginTop: 4 }}
                />
              </Flex>
            </span>
          }
          showArrow={false}
          key="perusahaan"
          onClick={stopPropagation}
        >
          {itemsPerusahaan.map((item) => (
            <div
              key={item.key}
              style={{
                padding: "16px 6px",
                borderBottom: "0.5px solid #F7F7F7",
              }}
            >
              <a
                href={item.href}
                className="text-navbar font-medium text-sm family-sans"
              >
                {item.label}
              </a>
            </div>
          ))}
        </Panel>
      </Collapse>
    ),
    style: {
      padding: "16px 20px",
    },
  },
  {
    label: <Divider className="my-0" />,
  },
  {
    key: "6",
    label: (
      <a
        href="https://www.aktiva.co.id/kontak/"
        className="text-navbar font-medium text-sm family-sans"
      >
        Kontak
      </a>
    ),
    style: {
      padding: "16px 20px",
    },
  },
];

export const itemsDetail = [
  {
    key: "1",
    label: (
      <a
        href="https://www.aktiva.co.id/"
        className="text-navbar font-medium text-sm p-2 family-sans"
      >
        Home
      </a>
    ),
    style: {
      padding: "16px 20px",
    },
  },
  {
    key: "2",
    label: (
      <a
        href="https://www.aktiva.co.id/solusi-kami/"
        className="text-navbar font-medium text-sm p-2 family-sans"
      >
        <Dropdown
          overlay={<Menu items={itemsSolusi} style={{ maxWidth: 217 }} />}
        >
          <span>
            Solusi Kami{" "}
            <FontAwesomeIcon
              icon={icon({
                name: "angle-down",
                family: "classic",
                style: "solid",
              })}
            />
          </span>
        </Dropdown>
      </a>
    ),
    style: {
      padding: "16px 20px",
    },
  },
  {
    key: "3",
    label: (
      <Link
        to={"/templates"}
        className="text-success font-medium text-sm p-2 family-sans"
      >
        Template
      </Link>
    ),
    style: {
      padding: "16px 20px",
    },
  },
  {
    key: "4",
    label: (
      <a
        href="https://www.aktiva.co.id/blog/"
        className="text-navbar font-medium text-sm p-2 family-sans"
      >
        Blog
      </a>
    ),
    style: {
      padding: "16px 20px",
    },
  },
  {
    key: "5",
    label: (
      <a href="#" className="text-navbar font-medium text-sm p-2 family-sans">
        <Dropdown
          overlay={<Menu items={itemsPerusahaan} style={{ maxWidth: 217 }} />}
        >
          <span>
            Perusahaan{" "}
            <FontAwesomeIcon
              icon={icon({
                name: "angle-down",
                family: "classic",
                style: "solid",
              })}
            />
          </span>
        </Dropdown>
      </a>
    ),
    style: {
      padding: "16px 20px",
    },
  },
  {
    key: "6",
    label: (
      <a
        href="https://www.aktiva.co.id/kontak/"
        className="text-navbar font-medium text-sm p-2 family-sans"
      >
        Kontak
      </a>
    ),
    style: {
      padding: "16px 20px",
    },
  },
];

export const itemsDetailSmall = (stopPropagation) => [
  {
    key: "1",
    label: (
      <a
        href="https://www.aktiva.co.id/"
        className="text-navbar font-medium text-sm family-sans"
      >
        Home
      </a>
    ),
    style: {
      padding: "16px 20px",
    },
  },
  {
    label: <Divider className="my-0" />,
  },
  {
    key: "2",
    label: (
      <Collapse ghost className="collapse-navbar" onClick={stopPropagation}>
        <Panel
          showArrow={false}
          header={
            <a
              href="https://www.aktiva.co.id/solusi-kami/"
              className="text-navbar text-sm family-sans"
            >
              Solusi Kami
            </a>
          }
          extra={
            <FontAwesomeIcon
              icon={icon({
                name: "angle-down",
                family: "classic",
                style: "solid",
              })}
              style={{ marginTop: 4 }}
            />
          }
          key="solusi"
          onClick={stopPropagation}
        >
          {itemsSolusi.map((item) => (
            <div key={item.key} style={{ padding: "16px 6px" }}>
              <a
                href={item.href}
                className="text-navbar font-medium text-sm family-sans"
              >
                {item.label}
              </a>
            </div>
          ))}
        </Panel>
      </Collapse>
    ),
    style: {
      padding: "16px 20px",
    },
  },
  {
    label: <Divider className="my-0" />,
  },
  {
    key: "3",
    label: (
      <a
        href="#"
        className="font-medium text-sm family-sans"
        style={{ color: "#0D9F3F" }}
      >
        Template
      </a>
    ),
    style: {
      padding: "16px 20px",
    },
  },
  {
    label: <Divider className="my-0" />,
  },
  {
    key: "4",
    label: (
      <a
        href="https://www.aktiva.co.id/blog/"
        className="text-navbar font-medium text-sm family-sans"
      >
        Blog
      </a>
    ),
    style: {
      padding: "16px 20px",
    },
  },
  {
    label: <Divider className="my-0" />,
  },
  {
    key: "5",
    label: (
      <Collapse ghost className="collapse-navbar" onClick={stopPropagation}>
        <Panel
          header={
            <span>
              <Flex justify="space-between">
                Perusahaan{" "}
                <FontAwesomeIcon
                  icon={icon({
                    name: "angle-down",
                    family: "classic",
                    style: "solid",
                  })}
                  style={{ marginTop: 4 }}
                />
              </Flex>
            </span>
          }
          showArrow={false}
          key="perusahaan"
          onClick={stopPropagation}
        >
          {itemsPerusahaan.map((item) => (
            <div key={item.key} style={{ padding: "16px 6px" }}>
              <a
                href={item.href}
                className="text-navbar font-medium text-sm family-sans"
              >
                {item.label}
              </a>
            </div>
          ))}
        </Panel>
      </Collapse>
    ),
    style: {
      padding: "16px 20px",
    },
  },
  {
    label: <Divider className="my-0" />,
  },
  {
    key: "6",
    label: (
      <a
        href="https://www.aktiva.co.id/kontak/"
        className="text-navbar font-medium text-sm family-sans"
      >
        Kontak
      </a>
    ),
    style: {
      padding: "16px 20px",
    },
  },
];
