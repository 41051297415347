import { Menu } from "antd";
import { ICLogoutOutlined, ICUserOutlined } from "components/list/ListIcon";
import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./sidebar.less";
import { handleLogout } from "utils/handleFunction";
import {
  PictureOutlined,
  LayoutOutlined,
  AppstoreOutlined,
  ControlOutlined,
} from "@ant-design/icons";

const LabelSidebar = ({ path, label }) => {
  return (
    <NavLink to={path}>
      <span className="nav-text">{label}</span>
    </NavLink>
  );
};

const Sidebar = () => {
  const { pathname: path } = useLocation();

  const [selectedMenu, setSelectedMenu] = useState("");

  const selectMenu = ({ key }) => {
    setSelectedMenu(key);
  };

  useEffect(() => {
    const userPath = path.split("manage/")[1];
    selectMenu({ key: userPath });
  }, [path]);

  const itemsUser = [
    {
      key: "main",
      label: <LabelSidebar path="main" label="Dashboard" />,
      icon: <AppstoreOutlined />,
    },
    {
      key: "templates",
      label: <LabelSidebar path="templates" label="My Templates" />,
      icon: <LayoutOutlined />,
    },
    {
      key: "gallery",
      label: <LabelSidebar path="gallery" label="Template Gallery" />,
      icon: <PictureOutlined />,
    },
    {
      key: "users",
      label: <LabelSidebar path="users" label="Manage User" />,
      icon: <ControlOutlined />,
    },
    {
      key: "profile",
      label: <LabelSidebar path="profile" label="Profil" />,
      icon: <ICUserOutlined />,
    },
  ];

  return (
    <>
      <Menu
        mode="inline"
        defaultSelectedKeys={[selectedMenu]}
        className="menu-sidebar"
        selectedKeys={selectedMenu}
        onSelect={selectMenu}
        inlineIndent={16}
        items={itemsUser}
      />
      <Menu
        mode="inline"
        inlineIndent={16}
        className="btn-logout-wrapper"
        onClick={handleLogout}
        items={[
          {
            key: 0,
            label: "Keluar",
            icon: <ICLogoutOutlined />,
          },
        ]}
      />
    </>
  );
};

export default Sidebar;
