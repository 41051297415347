import {
  Card,
  Col,
  Row,
  Pagination,
  Button,
  Input,
  Radio,
  Space,
  Tag,
  Image,
  Rate,
  Flex,
  Select,
  Grid,
  Table,
  Tooltip,
  Dropdown,
  ConfigProvider,
} from "antd";
import { EyeOutlined, FormOutlined, LayoutOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import {
  FileZipOutlined,
  FileTextOutlined,
  EditOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import AktivaLogo from "../../assets/images/aktiva_logo.png";
import { emptyTemplate } from "../Empty/EmptyComponent";
import { ICEditOutlined, ICEyeOutlined } from "components/list/ListIcon";
import { ActionMenu } from "components/Action";
import { useState } from "react";
import { ModalPreview } from "components/modal/ModalPreview";
import fault_error from "assets/images/fault_error.jpg";

const { useBreakpoint } = Grid;

export const CardFilter = ({
  searchValue,
  handleSearch,
  changeSearchQuery,
  changeCategory,
  width,
}) => {
  return (
    <>
      {width <= 991 ? (
        <Card>
          <Row gutter={24}>
            <Col
              {...(width >= 576 && {
                span: 12,
              })}
              {...(width >= 576
                ? {
                    className: "flex gap-x-3",
                  }
                : {
                    className: "flex gap-x-3 mb-6",
                  })}
            >
              <p className="flex items-center text-base font-bold mt-0 mb-0">
                Categories
              </p>
              <Select
                onChange={(data) => changeCategory(data)}
                defaultValue=""
                options={[
                  {
                    value: "",
                    label: "All Categories",
                  },
                  {
                    value: "Transactional",
                    label: "Transactional Email",
                  },
                  {
                    value: "Promotion",
                    label: "Promotion",
                  },
                  {
                    value: "Newsletter",
                    label: "Newsletter",
                  },
                  {
                    value: "Invitation",
                    label: "Invitation",
                  },
                  {
                    value: "Customer Feedback",
                    label: "Customer Feedback",
                  },
                ]}
              />
            </Col>
            <Col {...(width >= 576 ? { span: 12 } : { span: 24 })}>
              <Input
                placeholder="Search template"
                onChange={(e) => handleSearch(e.target.value)}
                onPressEnter={() => changeSearchQuery(searchValue)}
              />
            </Col>
          </Row>
        </Card>
      ) : (
        <Card style={{ width: 240, height: 540 }}>
          <Input
            placeholder="Search template"
            onChange={(e) => handleSearch(e.target.value)}
            onPressEnter={() => changeSearchQuery(searchValue)}
          />
          <Radio.Group
            className="py-6"
            defaultValue={""}
            onChange={(data) => changeCategory(data.target.value)}
          >
            <p className="text-base font-bold mt-0 mb-3">Categories</p>
            <Space direction="vertical" className="gap-y-3">
              <Radio value="">All Categories</Radio>
              <Radio value="Transactional">Transactional Email</Radio>
              <Radio value="Promotion">Promotion</Radio>
              <Radio value="Newsletter">Newsletter</Radio>
              <Radio value="Invitation">Invitation</Radio>
              <Radio value="Customer Feedback">Customer Feedback</Radio>
            </Space>
          </Radio.Group>
        </Card>
      )}
    </>
  );
};

export const CardTemplate = ({
  ListTemplate,
  showSkeleton,
  filterTemplate,
  changePage,
  currentPage,
  width,
  newTemplate,
  next,
}) => {
  const cardsPerPage = filterTemplate.per_page;
  const totalCards = ListTemplate?.data?.count;
  const [openModalPreview, setOpenModalPreview] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const loadingSkeletons = Array.from({ length: newTemplate ? 8 : 12 }).map(
    (_, index) => (
      <Col
        key={index}
        xs={24}
        sm={12}
        md={12}
        lg={newTemplate ? 6 : 8}
        xl={newTemplate ? 6 : 8}
      >
        <Card loading />
      </Col>
    )
  );

  return (
    <>
      {!ListTemplate || showSkeleton ? (
        <Row
          {...(width < 576
            ? { gutter: [0, 24] }
            : width <= 767
            ? !newTemplate
              ? { gutter: [16, 40] }
              : { gutter: [16, 32] }
            : !newTemplate
            ? { gutter: [24, 40] }
            : { gutter: [24, 32] })}
        >
          {loadingSkeletons}
        </Row>
      ) : (
        <>
          {ListTemplate?.code !== 200 ? (
            <Col
              className="flex flex-col items-center justify-center"
              style={{ height: "38vh" }}
            >
              {emptyTemplate({ statusCode: ListTemplate?.code })}
            </Col>
          ) : (
            <Row
              {...(width < 576
                ? { gutter: [0, 24] }
                : width <= 767
                ? !newTemplate
                  ? { gutter: [16, 40] }
                  : { gutter: [16, 32] }
                : !newTemplate
                ? { gutter: [24, 40] }
                : { gutter: [24, 32] })}
            >
              {ListTemplate?.data?.records.map((card, index) => (
                <Col
                  xs={24}
                  sm={12}
                  md={12}
                  lg={newTemplate ? 6 : 8}
                  xl={newTemplate ? 6 : 8}
                  key={index}
                >
                  <Card
                    hoverable={true}
                    cover={
                      <div className="card-image-container">
                        <div
                          className="image-cover"
                          style={{
                            backgroundImage: `url(${card.screenshot})`,
                            backgroundSize: "cover",
                            backgroundPosition: "top",
                            height: newTemplate ? 320 : 430,
                          }}
                        />
                        <div className="hover-button-container">
                          <Flex vertical gap={8}>
                            {newTemplate && (
                              <Button
                                style={{ padding: "6px 12px", border: 0 }}
                                onClick={() => {
                                  setSelectedCard(card);
                                  setOpenModalPreview(true);
                                }}
                              >
                                <EyeOutlined />
                                <span style={{ marginInlineStart: 4 }}>
                                  Preview
                                </span>
                              </Button>
                            )}
                            {newTemplate ? (
                              <Button
                                type="primary"
                                style={{ padding: "6px 12px", border: 0 }}
                                onClick={() => next(card)}
                              >
                                <LayoutOutlined />
                                <span style={{ marginInlineStart: 4 }}>
                                  Choose Template
                                </span>
                              </Button>
                            ) : (
                              <Link to={`/templates/${card.uid}`}>
                                <Button
                                  type="primary"
                                  style={{ padding: "6px 12px", border: 0 }}
                                >
                                  <FormOutlined />
                                  <span style={{ marginInlineStart: 4 }}>
                                    Use Template
                                  </span>
                                </Button>
                              </Link>
                            )}
                          </Flex>
                        </div>
                      </div>
                    }
                  >
                    <div
                      className={`text-center ${
                        !newTemplate ? "mt-1 mb-3" : "mt-3 mb-6"
                      } `}
                    >
                      <p className="mb-2 mt-0 font-medium">{card.name}</p>
                      <Tag style={{ borderRadius: 12 }}>{card.category}</Tag>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          )}
        </>
      )}
      {ListTemplate?.code === 200 && totalCards > 12 && (
        <Flex
          justify="flex-end"
          className={`${newTemplate ? "mt-12" : "pt-4"}`}
        >
          <Pagination
            current={currentPage}
            total={totalCards}
            pageSize={cardsPerPage}
            onChange={changePage}
            showSizeChanger={false}
          />
        </Flex>
      )}
      {selectedCard && (
        <ModalPreview
          data={selectedCard}
          openModalPreview={openModalPreview}
          setOpenModalPreview={setOpenModalPreview}
        />
      )}
    </>
  );
};

export const CardDetailTemplate = ({ detailTemplate, width }) => {
  return (
    <>
      {width < 992 ? (
        <Row gutter={[0, 60]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <h3
              className="font-semibold mt-0 mb-6 max-w-400"
              style={{ fontSize: 28 }}
            >
              Edit & Customize this template for free quickly and easily
            </h3>
            <Flex gap={12} vertical>
              <Link to={`/template/edit/${detailTemplate.uid}`}>
                <Button
                  type="primary"
                  style={{ padding: "6px 12px", border: 0 }}
                >
                  <FormOutlined />
                  <span style={{ marginInlineStart: 4 }}>
                    Edit this Template
                  </span>
                </Button>
              </Link>
              <span className="mb-12">Free for All Users</span>
            </Flex>
            <Flex justify="flex-start">
              {width > 767 && (
                <Col sm={0} md={8}>
                  <Flex gap={12} vertical>
                    <h4 className="font-semibold text-2xl mt-0 mb-0">Rating</h4>
                    <Rate className="mb-7" disabled={true} value={5} />
                  </Flex>
                </Col>
              )}
              <Col xs={12} sm={12} md={8}>
                <h4 className="font-semibold text-2xl mt-0 mb-3">Categories</h4>
                <Tag
                  style={{
                    borderRadius: 12,
                  }}
                  className="text-sm text-center mb-7"
                >
                  {detailTemplate?.category}
                </Tag>
              </Col>
              <Col xs={12} sm={12} md={8}>
                <Flex gap={12} vertical>
                  <h4 className="font-semibold text-2xl mt-0 mb-0">
                    Export As
                  </h4>
                  <div className="flex mb-7">
                    <Tag
                      style={{
                        padding: "8px 12px",
                        borderRadius: 16,
                      }}
                      className="flex items-center gap-x-1 text-sm"
                    >
                      <FileZipOutlined style={{ fontSize: 24 }} /> .zip file
                    </Tag>
                    <Tag
                      style={{ padding: "8px 12px", borderRadius: 16 }}
                      className="flex items-center gap-x-1 text-sm"
                    >
                      <FileTextOutlined style={{ fontSize: 24 }} /> .html file
                    </Tag>
                  </div>
                </Flex>
              </Col>
            </Flex>
            <h4 className="font-semibold text-2xl mt-0 mb-5">
              Platform Recommendation
            </h4>
            <div className="flex gap-x-5">
              <Image
                src={AktivaLogo}
                style={{ width: 73, height: 80 }}
                maxWidth={75}
                preview={false}
              />
              <div className="flex flex-col gap-y-2">
                <a
                  href="https://www.aktiva.co.id/mail-campaign/"
                  className="text-primary"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h5 className="font-semibold text-xl mt-0 mb-0">
                    Aktiva Mail Campaign
                  </h5>
                </a>
                <span className="text-base">
                  Layanan email blast (SaaS) untuk mengirimkan email broadcast
                  seperti email campaign, promosi, newsletter, billing dan lain
                  sebagainya.
                </span>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Image src={detailTemplate?.screenshot} />
          </Col>
        </Row>
      ) : (
        <Row gutter={60}>
          <Col lg={12} xl={12}>
            <Image src={detailTemplate?.screenshot} />
          </Col>
          <Col lg={12} xl={12} className="mt-16">
            <h3
              className="font-semibold mt-0 mb-6 max-w-400"
              style={{ fontSize: 28 }}
            >
              Edit & Customize this template for free quickly and easily
            </h3>
            <Flex gap={12} vertical>
              <Link to={`/template/edit/${detailTemplate.uid}`}>
                <Button
                  type="primary"
                  style={{ padding: "6px 12px", border: 0 }}
                >
                  <FormOutlined />
                  <span style={{ marginInlineStart: 4 }}>
                    Edit this Template
                  </span>
                </Button>
              </Link>
              <span className="mb-12">Free for All Users</span>
            </Flex>
            <Flex gap={12} vertical>
              <h4 className="font-semibold text-2xl mt-0 mb-0">Rating</h4>
              <Rate className="mb-7" disabled={true} value={5} />
            </Flex>
            <h4 className="font-semibold text-2xl mt-0 mb-3">Categories</h4>
            <Tag
              style={{
                borderRadius: 12,
              }}
              className="text-sm text-center mb-7"
            >
              {detailTemplate?.category}
            </Tag>
            <Flex gap={12} vertical>
              <h4 className="font-semibold text-2xl mt-0 mb-0">Export As</h4>
              <div className="flex mb-7">
                <Tag
                  style={{
                    padding: "8px 12px",
                    borderRadius: 16,
                  }}
                  className="flex items-center gap-x-1 text-sm"
                >
                  <FileZipOutlined style={{ fontSize: 24 }} /> .zip file
                </Tag>
                <Tag
                  style={{ padding: "8px 12px", borderRadius: 16 }}
                  className="flex items-center gap-x-1 text-sm"
                >
                  <FileTextOutlined style={{ fontSize: 24 }} /> .html file
                </Tag>
              </div>
            </Flex>
            <h4 className="font-semibold text-2xl mt-0 mb-5">
              Platform Recommendation
            </h4>
            <div className="flex gap-x-5">
              <Image
                src={AktivaLogo}
                style={{ width: 73, height: 80 }}
                maxWidth={75}
                preview={false}
              />
              <div className="flex flex-col gap-y-2">
                <a
                  href="https://www.aktiva.co.id/mail-campaign/"
                  className="text-primary"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h5 className="font-semibold text-xl mt-0 mb-0">
                    Aktiva Mail Campaign
                  </h5>
                </a>
                <span className="text-base">
                  Layanan email blast (SaaS) untuk mengirimkan email broadcast
                  seperti email campaign, promosi, newsletter, billing dan lain
                  sebagainya.
                </span>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export const CardRecomendation = ({ ListTemplate, showSkeleton, width }) => {
  const screens = useBreakpoint();
  const XLargeScreen = screens.xl;
  const MediumScreen = screens.md;

  const numberOfCards = width > 991 ? 3 : 4;

  const recordsToRender = ListTemplate?.data?.records.slice(0, numberOfCards);

  const loadingSkeletons = Array.from({ length: 4 }).map((_, index) => (
    <Col key={index} xs={24} sm={12} md={12} lg={8} xl={8}>
      <Card loading />
    </Col>
  ));

  return (
    <>
      {!ListTemplate || showSkeleton ? (
        <Row
          {...(width < 576
            ? { gutter: [0, 24] }
            : width <= 767
            ? { gutter: [16, 40] }
            : { gutter: [24, 40] })}
        >
          {loadingSkeletons}
        </Row>
      ) : (
        <>
          <Row
            {...(width < 576
              ? { gutter: [0, 24] }
              : width <= 767
              ? { gutter: [16, 40] }
              : { gutter: [24, 40] })}
          >
            {ListTemplate?.code !== 200 ? (
              <Col
                className="flex flex-col items-center justify-center"
                style={{ height: "38vh" }}
              >
                {emptyTemplate({ statusCode: ListTemplate?.code })}
              </Col>
            ) : (
              recordsToRender.map((card, index) => (
                <Col xs={24} sm={12} md={12} lg={8} xl={8} key={index}>
                  <Card
                    hoverable={true}
                    cover={
                      <div className="card-image-container">
                        <div
                          className="image-cover"
                          style={{
                            backgroundImage: `url(${card.screenshot})`,
                            backgroundSize: "cover",
                            backgroundPosition: "top",
                            height: XLargeScreen
                              ? 480
                              : MediumScreen && !XLargeScreen
                              ? 400
                              : 360,
                          }}
                        />
                        <div className="hover-button-container">
                          <Link to={`/templates/${card.uid}`}>
                            <Button
                              type="primary"
                              style={{ padding: "6px 12px", border: 0 }}
                            >
                              <FormOutlined />
                              <span style={{ marginInlineStart: 4 }}>
                                Use Template
                              </span>
                            </Button>
                          </Link>
                        </div>
                      </div>
                    }
                  >
                    <div className="text-center mt-1 mb-3">
                      <p className="mb-2 mt-0 font-medium">{card.name}</p>
                      <Tag style={{ borderRadius: 12 }}>{card.category}</Tag>
                    </div>
                  </Card>
                </Col>
              ))
            )}
          </Row>
        </>
      )}
    </>
  );
};

export const CardMyTemplate = ({
  ListTemplate,
  showSkeleton,
  width,
  propsFilter,
  propsValue,
  galleryPage,
  callbackList,
}) => {
  const navigate = useNavigate();
  const { changePage } = propsFilter;
  const {
    currentPage,
    currentPerPage,
    CategoryData,
    userData,
    recordRenderState,
    recordStatusCode,
    totalCards,
    listCount,
    publicUrls,
  } = propsValue;

  const loadingSkeletons = Array.from({ length: 20 }).map((_, index) => (
    <Col key={index} xs={24} sm={12} md={12} lg={6} xl={6}>
      <Card loading />
    </Col>
  ));

  return (
    <>
      {!ListTemplate || showSkeleton ? (
        <Row
          {...(width < 576
            ? { gutter: [0, 24] }
            : width <= 767
            ? { gutter: [16, 32] }
            : { gutter: [24, 32] })}
        >
          {loadingSkeletons}
        </Row>
      ) : (
        <>
          {recordStatusCode !== 200 || (!galleryPage && totalCards) ? (
            <Col
              className="flex flex-col items-center justify-center"
              style={{ height: "45vh" }}
            >
              {emptyTemplate({
                statusCode: recordStatusCode,
                totalCards: totalCards,
                propsValue: propsValue,
              })}
            </Col>
          ) : (
            <Row
              {...(width < 576
                ? { gutter: [0, 24] }
                : width <= 767
                ? { gutter: [16, 32] }
                : { gutter: [24, 32] })}
            >
              {recordRenderState.map((card, index) => (
                <Col xs={24} sm={12} md={12} lg={6} xl={6} key={index}>
                  <Card
                    hoverable={true}
                    cover={
                      <div
                        style={{
                          backgroundImage: `url(${
                            (!galleryPage && publicUrls[card.id]) ||
                            (galleryPage && card.screenshot) ||
                            fault_error
                          })`,
                          backgroundSize: "cover",
                          backgroundPosition: "top",
                          height: 320,
                        }}
                      />
                    }
                    className="custom-meta"
                    actions={[
                      <Tooltip title="See details">
                        <Link to={`${galleryPage ? card.uid : card.id}`}>
                          <EyeOutlined key="setting" />
                        </Link>
                      </Tooltip>,
                      <Tooltip title="Edit template">
                        <EditOutlined
                          key="edit"
                          onClick={() =>
                            navigate(
                              `/manage/${
                                galleryPage ? "gallery" : "templates"
                              }/edit/${galleryPage ? card.uid : card.id}`,
                              { state: card }
                            )
                          }
                        />
                      </Tooltip>,
                      <Tooltip title="More">
                        <Dropdown
                          trigger={["click"]}
                          placement="bottomRight"
                          overlay={() => (
                            <ActionMenu
                              data={card}
                              galleryPage={galleryPage}
                              callbackList={callbackList}
                              CategoryData={CategoryData}
                              userData={userData}
                            />
                          )}
                        >
                          <EllipsisOutlined key="ellipsis" />
                        </Dropdown>
                      </Tooltip>,
                    ]}
                  >
                    <div className="text-center">
                      <p className="mb-2 mt-0 font-medium">{card.name}</p>
                      <Tag style={{ borderRadius: 12 }}>
                        {!galleryPage
                          ? card.templateCategory === 1
                            ? "Transactional"
                            : card.templateCategory === 2
                            ? "Promotion"
                            : card.templateCategory === 3
                            ? "Newsletter"
                            : card.templateCategory === 4
                            ? "Invitation"
                            : "Customer Feedback"
                          : card.category}
                      </Tag>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          )}
          {recordStatusCode === 200 && listCount > 20 && (
            <Flex justify="flex-end" className="pt-4">
              <Pagination
                current={currentPage}
                total={listCount}
                pageSize={currentPerPage}
                onChange={changePage}
                showSizeChanger={false}
              />
            </Flex>
          )}
        </>
      )}
    </>
  );
};

export const ListMyCard = ({
  showSkeleton,
  propsValue,
  galleryPage,
  callbackList,
  propsFilter,
}) => {
  const navigate = useNavigate();
  const { changePage } = propsFilter;
  const {
    currentPage,
    currentPerPage,
    CategoryData,
    userData,
    recordRenderState,
    recordStatusCode,
    totalCards,
    listCount,
    publicUrls,
  } = propsValue;

  const ColumnList = [
    {
      title: "Preview",
      dataIndex: "screenshot",
      key: "Preview",
      className: "image-cell",
      width: 132,
      render: (_, record) => {
        return (
          <div
            style={{
              backgroundImage: `url(${
                (!galleryPage && publicUrls[record.id]) ||
                (galleryPage && record.screenshot) ||
                fault_error
              })`,
              backgroundSize: "cover",
              backgroundPosition: "top",
              height: 80,
            }}
          />
        );
      },
    },
    {
      title: "Template name",
      dataIndex: "name",
      key: "Template name",
    },
    {
      title: "Category",
      ...(!galleryPage
        ? { dataIndex: "templateCategory" }
        : { dataIndex: "category" }),
      key: "Category",
      width: 160,
      render: (category) => {
        return (
          <Tag style={{ borderRadius: 12 }}>
            {!galleryPage
              ? category === 1
                ? "Transactional"
                : category === 2
                ? "Promotion"
                : category === 3
                ? "Newsletter"
                : category === 4
                ? "Invitation"
                : "Customer Feedback"
              : category}
          </Tag>
        );
      },
    },
    {
      key: "actions",
      fixed: "right",
      width: 220,
      render: (_, record) => (
        <Flex justify="center" align="center" gap={4}>
          <Tooltip title="Edit template">
            <Button
              type="primary"
              className="text-success"
              size="medium"
              icon={<ICEditOutlined />}
              onClick={() =>
                navigate(
                  `/manage/${galleryPage ? "gallery" : "templates"}/edit/${
                    galleryPage ? record.uid : record.id
                  }`,
                  { state: record }
                )
              }
            />
          </Tooltip>
          <Tooltip title="See details">
            <Link to={`${galleryPage ? record.uid : record.id}`}>
              <Button
                className="text-success btn-list"
                icon={<ICEyeOutlined />}
                size="medium"
              />
            </Link>
          </Tooltip>
          <Tooltip title="More">
            <Dropdown
              trigger={["click"]}
              placement="bottomRight"
              overlay={() => (
                <ActionMenu
                  data={record}
                  galleryPage={galleryPage}
                  callbackList={callbackList}
                  CategoryData={CategoryData}
                  userData={userData}
                />
              )}
            >
              <Button size="medium">
                More
                <EllipsisOutlined className="icon-list" />
              </Button>
            </Dropdown>
          </Tooltip>
        </Flex>
      ),
    },
  ];

  return (
    <ConfigProvider
      renderEmpty={() => (
        <Col
          className="flex flex-col items-center justify-center"
          style={{ height: "45vh" }}
        >
          {emptyTemplate({
            statusCode: recordStatusCode,
            totalCards: totalCards,
            propsValue: propsValue,
          })}
        </Col>
      )}
    >
      <Table
        columns={ColumnList}
        dataSource={recordRenderState}
        style={{
          overflowX: "auto",
          maxWidth: "100%",
        }}
        pagination={{
          pageSize: currentPerPage,
          showSizeChanger: false,
          current: currentPage,
          total: listCount,
          onChange: changePage,
        }}
        loading={showSkeleton}
      />
    </ConfigProvider>
  );
};
