import { setLogout } from "./helpers";
import { deleteUser, supabase } from "supabase";
import { Modal, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import ModalConfirm from "components/modal/ModalConfirm";
import ModalSuccess from "components/modal/ModalSuccess";
import ModalWarning from "components/modal/ModalWarning";

export const handleLogout = async () => {
  await supabase.auth.signOut();
  setLogout("user");
  window.location.href = "/manage/login";
};

export const HandleExitBuilder = ({
  openModalDelete,
  setOpenModalDelete,
  TemplateID,
}) => {
  const navigate = useNavigate();

  const { id } = useParams();
  const { uid } = useParams();

  return (
    <Modal
      centered
      title="Exit without save template?"
      open={openModalDelete}
      width={540}
      okText="Yes Exit"
      onOk={() => {
        setOpenModalDelete(false);
        if (TemplateID) {
          if (id) {
            navigate(`/manage/templates/${TemplateID}`);
          }
          if (uid) {
            navigate(`/manage/gallery/${TemplateID}`);
          }
        } else {
          navigate(-1);
        }
      }}
      cancelText="Cancel"
      onCancel={() => setOpenModalDelete(false)}
      okButtonProps={{ type: "danger" }}
    >
      <Typography.Text>
        Are you sure want to exit and discard your changes on this template?
        <br />
        <br /> If you want to save your work, please save your email template
        first.
      </Typography.Text>
    </Modal>
  );
};

export const HandleDeleteUsers = ({ record, callbackManageList }) => {
  ModalConfirm({
    title: "Delete admin account",
    customMessage: (
      <>
        Apakah anda yakin ingin menghapus akun{" "}
        <span className="font-medium">{record.full_name}</span> tersebut?
        <br />
        <br /> Akun admin yang sudah dihapus, tidak dapat dibatalkan kembali
      </>
    ),
    okText: "Delete User",
    cancelText: "Cancel",
    type: "danger",
    className: "modal-confirm-delete",
    width: 600,
    onOk: async () => {
      const { data, error } = await deleteUser({ id: record.id });
      if (data && !error) {
        ModalSuccess({
          title: "Admin account deleted successfully",
          customMessage: "Akun admin tersebut berhasil dihapus dari sistem.",
          width: 500,
          type: "primary",
          onOk: () => callbackManageList(),
        });
      } else {
        ModalWarning({
          title: "Failed to delete admin account",
          customMessage:
            "Akun admin gagal dihapus. Terdapat kendala pada sistem.",
          width: 500,
          type: "primary",
        });
      }
    },
  });
};
