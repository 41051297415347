import { Card, Col, Flex, Image, Input, Row, Select, Typography } from "antd";
import { BtnGroupDetail, BtnSave } from "components/AntButton";
import Spinner from "components/Spinner/Spinner";
import DashboardPageHeader from "components/dashboard-header/DashboardPageHeader";
import ModalSuccess from "components/modal/ModalSuccess";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getDataFromDB, signInPublicURL, updateDataToDB } from "supabase";
import useGetTemplateCategory from "utils/hooks/useGetTemplateCategory";
import fault_error from "assets/images/fault_error.jpg";

export const DetailTemplate = ({ userData }) => {
  useGetTemplateCategory();
  const { id } = useParams();
  const { uid } = useParams();
  const [detailTemplate, setDetailTemplate] = useState(null);
  const [inputName, setInputName] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const CategoryTemplate = useSelector(
    ({ templateCategory }) => templateCategory.value
  );
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [publicUrls, setPublicUrls] = useState({});

  const getDetailTemplate = useCallback(async () => {
    if (id) {
      const { data } = await getDataFromDB({
        from: "userTemplate",
        select: ["id", "name", "templateCategory", "screenshot", "contentJSON"],
        eq: "id",
        value: id,
      });
      setDetailTemplate({ data });
    }
    if (uid) {
      const response = await fetch(
        `${process.env.REACT_APP_BE_BASE_URL}/aktiva-templates/${uid}?edit=true`,
        {
          headers: {
            "X-Api-Key": process.env.REACT_APP_API_KEY,
          },
        }
      );
      const { data } = await response.json();
      setDetailTemplate({ data });
    }
  }, [id, uid]);

  useEffect(() => {
    getDetailTemplate();
  }, [getDetailTemplate]);

  useEffect(() => {
    if (Array.isArray(CategoryTemplate)) {
      const category = CategoryTemplate.map((category) => ({
        value: category.id,
        label: category.name,
      }));
      setCategoryOptions(category);
    }
  }, [CategoryTemplate]);

  const recordRenderState = id && detailTemplate?.data;
  const templateImage =
    detailTemplate?.data?.record && detailTemplate?.data?.record?.screenshot;
  const templateName = detailTemplate?.data?.record
    ? detailTemplate?.data?.record?.name
    : detailTemplate?.data[0]?.name;
  const templateCategory = detailTemplate?.data?.record
    ? detailTemplate?.data?.record?.category
    : detailTemplate?.data[0]?.templateCategory;

  useEffect(() => {
    if (recordRenderState && recordRenderState.length > 0) {
      const fetchPublicUrls = async () => {
        const urls = {};
        let hasNullUrl = false;

        for (const card of recordRenderState) {
          const url = await signInPublicURL({
            from: "template-images",
            file: card.screenshot,
          });
          if (url.endsWith("/null")) {
            hasNullUrl = true;
          }
          urls[card.id] = url;
        }
        if (hasNullUrl) {
          setPublicUrls({});
        } else {
          setPublicUrls(urls);
        }
      };

      fetchPublicUrls();
    }
  }, [recordRenderState]);

  const handleSaveTemplate = async () => {
    const { status } = await updateDataToDB({
      from: "userTemplate",
      update: {
        name: !inputName ? templateName : inputName,
        templateCategory: !selectedCategory
          ? templateCategory
          : selectedCategory,
      },
      eq: "id",
      value: id,
    });
    if (status === 200) {
      ModalSuccess({
        title: "Email template saved successfully",
        customMessage: `Email template ${
          inputName || templateName
        } successfully saved.`,
        type: "primary",
        width: 416,
        onOk: () => getDetailTemplate(),
      });
    }
  };

  if (!detailTemplate) return <Spinner full />;

  return (
    <>
      <DashboardPageHeader
        title="Template Details"
        extra={
          <BtnGroupDetail
            data={
              detailTemplate?.data?.record
                ? detailTemplate?.data?.record
                : detailTemplate?.data[0]
            }
            disable={true}
            CategoryData={CategoryTemplate}
            userData={userData}
          />
        }
        templateIdentity={id ? id : uid}
      />
      <Row className="px-6 py-3">
        <Col span={24}>
          <Card className="card-blank">
            <Row>
              <Col span={12} className="image-scrollable">
                <Image
                  src={
                    publicUrls[detailTemplate?.data[0]?.id] ||
                    templateImage ||
                    fault_error
                  }
                />
              </Col>
              <Col span={12} className="pl-8">
                <Flex vertical className="pt-6">
                  <Flex align="center" justify="space-between" className="pb-6">
                    <Typography.Text className="text-3-xl font-medium">
                      Edit Details
                    </Typography.Text>
                    <BtnSave
                      title="Save"
                      templateName={templateName}
                      handleSaveTemplate={handleSaveTemplate}
                      disable={uid ? true : false}
                    />
                  </Flex>
                  <Typography.Text>Template Name</Typography.Text>
                  <Input
                    placeholder="Your template name"
                    {...(templateName && {
                      value: inputName ? inputName : templateName,
                    })}
                    className="mt-1"
                    onChange={(e) => setInputName(e.target.value)}
                  />
                  <Typography.Text className="pt-6">
                    Email Category
                  </Typography.Text>
                  <Select
                    defaultValue=""
                    className="mt-1"
                    onChange={(data) => setSelectedCategory(data)}
                    {...(templateCategory && {
                      value: selectedCategory
                        ? selectedCategory
                        : templateCategory,
                    })}
                    {...(id
                      ? {
                          options: [
                            { value: "", label: "Semua Template" },
                            ...categoryOptions,
                          ],
                        }
                      : {
                          options: [
                            {
                              value: "",
                              label: "All Categories",
                            },
                            {
                              value: "Transactional",
                              label: "Transactional Email",
                            },
                            {
                              value: "Promotion",
                              label: "Promotion",
                            },
                            {
                              value: "Newsletter",
                              label: "Newsletter",
                            },
                            {
                              value: "Invitation",
                              label: "Invitation",
                            },
                            {
                              value: "Customer Feedback",
                              label: "Customer Feedback",
                            },
                          ],
                        })}
                    disabled={!CategoryTemplate}
                  />
                </Flex>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
