import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoutes = ({
  userData,
  redirectPath = "/manage/login",
  children,
  token,
}) => {
  let element = children ? children : <Outlet />;
  if (!token) {
    element = <Navigate to={redirectPath} replace />;
  } else if (
    userData &&
    (userData.role === "service_role" || userData.role === "authenticated")
  ) {
    element = children;
  }

  return element;
};

export default ProtectedRoutes;
