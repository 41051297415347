import { Menu } from "antd";
import {
  ICCopyOutlined,
  ICDeleteOutlined,
  ICEditOutlined,
  ICEyeOutlined,
} from "./list/ListIcon";
import { useState } from "react";
import { ModalDeleteMyTemplate } from "./modal/ModalDelete";
import { useNavigate } from "react-router-dom";
import { ModalDuplicate } from "./modal/ModalDuplicate";

export const ActionMenu = ({
  data,
  detailPage,
  galleryPage,
  callbackList,
  CategoryData,
  userData,
}) => {
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalDuplicate, setOpenModalDuplicate] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <Menu
        items={[
          !detailPage && {
            label: "See Template Details",
            key: 1,
            icon: <ICEyeOutlined />,
            onClick: () =>
              navigate(`${galleryPage ? data.uid : data.id}`, {
                state: CategoryData,
              }),
          },
          !detailPage && {
            label: "Edit Template",
            key: 2,
            icon: <ICEditOutlined />,
            onClick: () =>
              navigate(
                `/manage/${galleryPage ? "gallery" : "templates"}/edit/${
                  galleryPage ? data.uid : data.id
                }`,
                { state: data }
              ),
          },
          !detailPage &&
            !galleryPage && {
              label: "Duplicate Template",
              key: 3,
              icon: <ICCopyOutlined />,
              onClick: () => setOpenModalDuplicate(true),
            },
          {
            label: "Delete Template",
            key: 4,
            icon: <ICDeleteOutlined />,
            danger: true,
            onClick: () => setOpenModalDelete(true),
          },
        ]}
      />
      {data && (
        <>
          <ModalDeleteMyTemplate
            openModalDelete={openModalDelete}
            setOpenModalDelete={setOpenModalDelete}
            data={data}
            callbackList={callbackList}
            detailPage={detailPage}
          />
          <ModalDuplicate
            openModalDuplicate={openModalDuplicate}
            setOpenModalDuplicate={setOpenModalDuplicate}
            data={data}
            CategoryData={CategoryData}
            userData={userData}
            callbackList={callbackList}
          />
        </>
      )}
    </>
  );
};
