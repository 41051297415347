import { createSlice } from "@reduxjs/toolkit";

export const templateCategory = createSlice({
  name: "templateCategory",
  initialState: {
    value: false,
  },
  reducers: {
    // reducers action
    setTemplateCategory: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setTemplateCategory } = templateCategory.actions;
export default templateCategory.reducer;
