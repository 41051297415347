import { Form, Input, Modal, Select } from "antd";
import { regexEmail, regexPassword, requiredRules } from "utils/helpers";
import ModalSuccess from "./ModalSuccess";
import { createNewUser, updateUser } from "supabase";
import { useState } from "react";
import ModalWarning from "./ModalWarning";

const { Option } = Select;

export const ModalManageUsers = ({
  title,
  open,
  editState,
  close,
  form,
  profileState,
  callbackManageList,
  refreshUser,
  emailData,
}) => {
  const [disableBtn, setDisableBtn] = useState(false);

  const handleValuesChange = (_, allValues) => {
    const isName = allValues.full_name === "";
    const isEmailValid = regexEmail.test(allValues.email);
    const isRole = allValues.role === "";
    const isPasswordValid = regexPassword.test(allValues.password);

    if (
      isName ||
      !isEmailValid ||
      isRole ||
      (!editState && !profileState && !isPasswordValid && !isRole)
    ) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  };

  const handleSaveUsers = async () => {
    const formValues = form.getFieldsValue();
    if (profileState || editState) {
      const { data: dataEdited, error } = await updateUser({
        uid: formValues.uidUser,
        ...(emailData !== formValues.email && { email: formValues.email }),
        role: formValues.role,
        data: { full_name: formValues.full_name, role: formValues.role },
      });
      if (!dataEdited.user && error && error.status === 422) {
        ModalWarning({
          title: "Email address already exist",
          customMessage:
            "Failed edit account. Email address already exist, please use another email address.",
          type: "primary",
          width: 500,
          onOk: close,
        });
      } else if (dataEdited) {
        ModalSuccess({
          title: profileState
            ? "Account profile edited successfully"
            : "User edited successfully",
          customMessage: profileState
            ? "Your account successfully updated in the system."
            : "User account successfully edited on system.",
          type: "primary",
          onOk: async () => {
            close();
            await refreshUser();
            await callbackManageList();
          },
        });
      } else {
        ModalWarning({
          title: "Failed to edit user account",
          customMessage: (
            <span>
              Failed edit account. There is problem on the system.
              <br />
              <br />
              Problem: {error}
            </span>
          ),
          type: "primary",
          width: 500,
          onOk: close,
        });
      }
    } else {
      const { data: dataNew, error } = await createNewUser({
        email: formValues.email,
        password: formValues.password,
        role: formValues.role,
        data: { full_name: formValues.full_name, role: formValues.role },
      });
      if (!dataNew.user && error && error.status === 422) {
        ModalWarning({
          title: "Email address already exist",
          customMessage:
            "Failed create account. Email address already exist, please use another email address.",
          type: "primary",
          width: 500,
          onOk: close,
        });
      } else if (dataNew) {
        ModalSuccess({
          title: "User created successfully",
          customMessage: "User account successfully create on system.",
          type: "primary",
          onOk: () => {
            close();
            if (!profileState) {
              callbackManageList();
            }
          },
        });
      } else {
        ModalWarning({
          title: "Failed to add new user",
          customMessage: (
            <span>
              Failed create account. There is problem on the system.
              <br />
              <br />
              Problem: {error}
            </span>
          ),
          type: "primary",
          width: 500,
          onOk: close,
        });
      }
    }
  };

  return (
    <Modal
      title={title}
      centered
      open={open}
      onCancel={close}
      width={572}
      onOk={handleSaveUsers}
      okText="Save"
      cancelText="Cancel"
      okButtonProps={{
        disabled: disableBtn,
        type: disableBtn ? "default" : "primary",
      }}
    >
      <Form form={form} onValuesChange={handleValuesChange}>
        <Form.Item
          label={profileState ? "Name" : "Full Name"}
          name="full_name"
          className="hide-padding my-4"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input placeholder="Full Name" />
        </Form.Item>
        <Form.Item
          label={profileState ? "Email Address" : "Alamat Email"}
          className="hide-padding mb-4"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          name="email"
          rules={[
            requiredRules,
            {
              pattern: regexEmail,
              message: "Harap masukkan alamat email yang vaild",
            },
          ]}
        >
          <Input placeholder="user@domain.tld" />
        </Form.Item>
        {!editState && !profileState && (
          <Form.Item
            label="Password"
            className="hide-padding"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="password"
            rules={[
              requiredRules,
              {
                pattern: regexPassword,
                message:
                  "Password harus mengandung 8 karakter dengan kombinasi huruf kapital, huruf kecil, angka dan simbol",
              },
            ]}
          >
            <Input.Password placeholder="Strong Password" />
          </Form.Item>
        )}
        {!profileState && (
          <Form.Item
            label="Role"
            className="hide-padding mb-4"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 18 }}
            name="role"
            rules={[requiredRules]}
          >
            <Select defaultValue="">
              <Option value="">Pilih role</Option>
              <Option value="service_role">Super User</Option>
              <Option value="authenticated">User</Option>
            </Select>
          </Form.Item>
        )}
        <Form.Item name="uidUser" hidden>
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
