import { Modal } from "antd";
import { ICExclamationCircleOutlined } from "../list/ListIcon";

const { confirm } = Modal;

const ModalConfirm = (props) => {
  const {
    okText = "Ya Hapus",
    width,
    okButtonProps,
    customMessage,
    cancelButtonProps,
    message,
    cancelText = "Batalkan",
    type,
    icon,
    className,
    footer,
    href,
    target,
  } = props;

  const messageModal = customMessage || <p className="mt-0 mb-0">{message}</p>;
  return confirm({
    ...props,
    icon: icon || <ICExclamationCircleOutlined />,
    className: className,
    content: messageModal,
    centered: true,
    okText: okText,
    okButtonProps: {
      ...okButtonProps,
      type: type,
      className: "btn-modal",
    },
    autoFocusButton: null,
    cancelText: cancelText,
    cancelButtonProps: {
      ...cancelButtonProps,
      className: "custom-cancel-button",
      href: href,
      target: target,
    },
    width: width || 600,
    footer: footer,
  });
};

export default ModalConfirm;
