import { createSlice } from "@reduxjs/toolkit";

export const manageUsers = createSlice({
  name: "manageUsers",
  initialState: {
    value: false,
  },
  reducers: {
    // reducers action
    setManageUsers: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setManageUsers } = manageUsers.actions;
export default manageUsers.reducer;
