import { Button, Flex, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

export const CardMain = ({ width, handleExploreTemplateClick }) => {
  return (
    <>
      <div className="flex flex-col items-center justify-center text-center">
        <h1
          {...(width < 576
            ? { className: "text-3xl mb-1 mt-0 font-semibold" }
            : { className: "text-4xl mb-1 mt-0 font-semibold" })}
        >
          Aktiva Email Templates
        </h1>
        <span
          {...(width < 576
            ? { className: "text-secondary font-normal text-sm mt-2" }
            : { className: "text-secondary font-normal text-base mt-2" })}
        >
          Template email gratis untuk meningkatkan performa bisnis anda
        </span>
      </div>
      <div className="flex justify-center mx-auto text-center items-center gap-y-3 pt-6 pb-15">
        <Button
          type="primary"
          htmlType="submit"
          style={{ marginRight: 6 }}
          onClick={() => handleExploreTemplateClick()}
        >
          Explore Template
        </Button>
        <Button
          type="default"
          className="text-success"
          style={{ color: "black", marginLeft: 6 }}
          href="https://www.aktiva.co.id/kontak/"
        >
          Kontak Sales
        </Button>
      </div>
    </>
  );
};

export const CardDetail = ({ detailTemplate, width }) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate("/templates");
  };
  return (
    <>
      <div className="flex flex-co items-start items-center">
        <h1
          {...(width < 992
            ? { className: "text-3xl mb-0 mt-0 font-semibold" }
            : { className: "text-4xl mb-0 mt-0 font-semibold" })}
        >
          <Flex vertical>
            <Text className="text-success font-normal mb-2">
              <Button
                onClick={handleBackClick}
                className="text-success font-normal p-0"
                type="link"
              >
                <FontAwesomeIcon
                  icon={icon({
                    name: "angle-left",
                    family: "classic",
                    style: "solid",
                  })}
                  className="mr-2"
                />
                Back to template list
              </Button>
            </Text>
            {detailTemplate?.name}
          </Flex>
        </h1>
      </div>
    </>
  );
};
