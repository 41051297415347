import { Button, notification, Flex } from "antd";

export const TemplateChanges = ({ dataTemplate }) => {
  return notification.warning({
    message: <span className="font-semibold">Template has been Changed</span>,
    description:
      "You have edit the content of this template recently. Do you want to leave without save or export template?",
    duration: null,
    placement: "top",
    style: {
      width: 480,
      padding: 28,
    },
    btn: (
      <>
        <Flex gap={8} className="pt-3">
          <Button
            className="custom-cancel-btn"
            onClick={() => {
              notification.destroy();
            }}
          >
            Stay on this page
          </Button>
          <Button
            type="danger"
            onClick={() => {
              window.location.href = `/templates/${dataTemplate.uid}`;
            }}
          >
            Leave
          </Button>
        </Flex>
      </>
    ),
  });
};
