import fetch from "@apicase/adapter-fetch";
import { ApiService, ApiTree } from "@apicase/services";
import apiList from "./list";

// VARIABLE LIST
// make sure match for your APP
const appBaseUrl = process.env.REACT_APP_BE_BASE_URL;
const api_key = process.env.REACT_APP_API_KEY;

const RootService = new ApiService({
  adapter: fetch,
  url: appBaseUrl,
  mode: "cors",
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
  options: { timeout: 1000 },
  hooks: {
    before({ payload, next }) {
      payload.headers = {
        ...payload.headers,
      };
      next(payload);
    },
  },
});

const MainService = new ApiTree(RootService, [
  {
    children: apiList,
    hooks: {
      before({ payload, next }) {
        const newPayload = { ...payload };
        newPayload.headers = {
          ...payload.headers,
          "X-Api-Key": api_key,
        };

        next(newPayload);
      },
      async fail({ result, next }) {
        next(result);
      },
      async done({ result, next }) {
        next(result);
      },
    },
  },
]);

export default MainService;
