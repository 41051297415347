import { Dropdown, Button, Menu, Grid } from "antd";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  itemsTemplate,
  itemsDetail,
  itemsTemplateSmall,
  itemsDetailSmall,
} from "./ListMenu";

const { useBreakpoint } = Grid;

export const MenuReguler = () => {
  const Location = useLocation();
  const TemplatePage = Location.pathname === "/templates";
  return (
    <>
      {TemplatePage
        ? itemsTemplate.map((item) => (
            <React.Fragment key={item.key}>{item.label}</React.Fragment>
          ))
        : itemsDetail.map((item) => (
            <React.Fragment key={item.key}>{item.label}</React.Fragment>
          ))}
    </>
  );
};

export const MenuHamburger = () => {
  const Location = useLocation();
  const screens = useBreakpoint();
  const SmallScreen = screens.sm;
  const XtraSmallScreen = screens.xs;
  const TemplatePage = Location.pathname === "/templates";

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleVisibleChange = (flag) => {
    setDropdownOpen(flag);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      <Dropdown
        overlay={
          <Menu
            items={
              TemplatePage
                ? XtraSmallScreen || SmallScreen
                  ? itemsTemplateSmall(stopPropagation)
                  : itemsTemplate
                : XtraSmallScreen || SmallScreen
                ? itemsDetailSmall(stopPropagation)
                : itemsDetail
            }
          />
        }
        trigger={["click"]}
        {...((XtraSmallScreen || SmallScreen) && {
          overlayStyle: {
            left: 0,
            right: 0,
            minWidth: "unset",
            paddingTop: "0.5rem",
          },
          overlayClassName: "dropdown-hamburger",
        })}
        open={dropdownOpen}
        onOpenChange={handleVisibleChange}
      >
        <Button
          type="text"
          icon={
            dropdownOpen ? (
              <CloseOutlined style={{ fontSize: "14px" }} />
            ) : (
              <MenuOutlined style={{ fontSize: "14px" }} />
            )
          }
        />
      </Dropdown>
    </>
  );
};
