import MainService from "./base";

const handleErrorResponse = (error = "API error handle response") =>
  console.error("API ERROR: ", error);

const handleGETRequestNew = async (api, body) => {
  const {
    result: {
      body: { status, code, msg, data },
    },
  } = await MainService(api)
    .doRequest({ query: body })
    .then((result) => result)
    .catch((errorGeneral) => {
      handleErrorResponse(`${api}: ${errorGeneral}`);
      return {
        result: {
          body: { data: null, error: null },
        },
        errorJS: errorGeneral,
      };
    });

  return {
    status,
    code,
    msg,
    data,
  };
};

/** Edit after this part */

export const getTemplateList = ({ ...filterTemplate }) =>
  handleGETRequestNew("getTemplateList", { ...filterTemplate });
