import { Card, Col, Flex, Row } from "antd";
import { CardMyTemplate, ListMyCard } from "components/Card/templatesCard";
import { FilterComponent, RadioTable } from "components/Components";
import DashboardHeader from "components/dashboard-header/DashboardHeader";
import useDocumentTitle from "utils/useDocumentTitle";
import { useCallback, useEffect, useState } from "react";
import { getTemplateList } from "api";

const TemplateGallery = () => {
  useDocumentTitle("Template Gallery");
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [dataTemplate, setDataTemplate] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [filterMyTemplate, setFilterMyTemplate] = useState({
    category: "",
    search: "",
    page: 1,
    per_page: "20",
  });
  const [inputValue, setInputValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(filterMyTemplate.page);
  const [currentPerPage, setPerPage] = useState(filterMyTemplate.per_page);
  const [currentCategory, setCurrentCategory] = useState(
    filterMyTemplate.category
  );
  const [radioValue, setRadioValue] = useState("gallery");

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const callbackList = useCallback(async () => {
    setShowSkeleton(true);
    const { data, code } = await getTemplateList(filterMyTemplate);
    setDataTemplate({ data, code });
    setShowSkeleton(false);
  }, [filterMyTemplate]);

  useEffect(() => {
    callbackList();
  }, [callbackList]);

  const handleSearch = (value) => {
    setSearchValue(inputValue);
    setFilterMyTemplate({
      ...filterMyTemplate,
      page: 1,
      search: value,
    });
  };

  const changeSearchQuery = (e) => {
    setInputValue(e.target.value);
  };

  const changePerPage = (page) => {
    setPerPage(page);
    setCurrentPage(1);
    setFilterMyTemplate({
      ...filterMyTemplate,
      per_page: page,
      page: 1,
    });
  };

  const changeCategory = (categories) => {
    setCurrentCategory(categories);
    setCurrentPage(1);
    setFilterMyTemplate({
      ...filterMyTemplate,
      category: categories,
      page: 1,
    });
  };

  const changePage = (page) => {
    setCurrentPage(page);
    setFilterMyTemplate({
      ...filterMyTemplate,
      page: page,
    });
  };

  const recordRenderState = dataTemplate?.data?.records;
  const recordLengthState = dataTemplate?.data
    ? dataTemplate?.data?.records && dataTemplate?.data?.records.length === 0
    : null;
  const recordStatusCode = dataTemplate?.code;
  const totalCards = recordLengthState;
  const listCount = dataTemplate?.data ? dataTemplate?.data?.count : null;

  const propsFilter = {
    handleSearch,
    changePage,
    changeSearchQuery,
    changePerPage,
    changeCategory,
  };

  const propsValue = {
    filterMyTemplate,
    inputValue,
    searchValue,
    currentPage,
    currentCategory,
    currentPerPage,
    dataTemplate,
    recordRenderState,
    recordStatusCode,
    totalCards,
    listCount,
  };

  return (
    <>
      <DashboardHeader title="Template Gallery" gallery={true} />
      <Row className="px-6 py-3">
        <Col span={24}>
          <Flex vertical gap={24}>
            <FilterComponent
              propsFilter={propsFilter}
              propsValue={propsValue}
              galleryPage={true}
            />
            <Card
              title="Email Template"
              extra={
                <RadioTable
                  setRadioValue={setRadioValue}
                  radioValue={radioValue}
                  propsValue={propsValue}
                />
              }
              className="card-Mytemplate"
            >
              {radioValue === "gallery" ? (
                <CardMyTemplate
                  ListTemplate={dataTemplate}
                  showSkeleton={showSkeleton}
                  width={width}
                  propsFilter={propsFilter}
                  propsValue={propsValue}
                  galleryPage={true}
                />
              ) : (
                <ListMyCard
                  ListTemplate={dataTemplate}
                  showSkeleton={showSkeleton}
                  width={width}
                  propsFilter={propsFilter}
                  propsValue={propsValue}
                  galleryPage={true}
                />
              )}
            </Card>
          </Flex>
        </Col>
      </Row>
    </>
  );
};

export default TemplateGallery;
