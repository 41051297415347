import { Descriptions, Flex, Tag } from "antd";

export const AccountInformation = ({ user }) => {
  return (
    <Descriptions
      layout="horizontal"
      column={1}
      bordered={false}
      className="desc-company"
      labelStyle={{
        width: 100,
        fontWeight: 500,
        color: "black",
        padding: "0px 16px",
      }}
      colon={false}
    >
      <Descriptions.Item label="Nama">
        {user.user_metadata.full_name}
      </Descriptions.Item>
      <Descriptions.Item label="Email">
        {user.new_email ? (
          <Flex gap={4}>
            {user.new_email}
            <Tag color="warning">unconfirmed</Tag>
          </Flex>
        ) : (
          user.email
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Role">
        {user.user_metadata.role === "service_role" ? "Super User" : "User"}
      </Descriptions.Item>
    </Descriptions>
  );
};
