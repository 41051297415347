import { useParams } from "react-router-dom";
import useDocumentTitle from "../utils/useDocumentTitle";
import { CardDetail } from "../components/Card/headerCard";
import { useCallback, useEffect, useState } from "react";
import {
  CardDetailTemplate,
  CardRecomendation,
} from "../components/Card/templatesCard";
import Spinner from "../components/Spinner/Spinner";
import { emptyTemplateDetail } from "../components/Empty/EmptyComponent";
import { Divider, Flex, Grid, Typography } from "antd";
import { Navbar } from "../components/Navbar/Navbar";
import { FooterPage } from "../components/Footer/Footer";
import { getTemplateList } from "../api";

const { useBreakpoint } = Grid;
const { Text } = Typography;

const DetailPage = () => {
  useDocumentTitle("Detail Template");
  const { uid } = useParams();
  const [detailTemplate, setDetailTemplate] = useState(null);
  const [dataRecomendation, setDataRecomendation] = useState(null);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const screen = useBreakpoint();
  const isLargeScreen = screen.lg;
  const categoryRecomendation = detailTemplate?.data?.record?.category;

  const getDetailTemplate = useCallback(async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BE_BASE_URL}/aktiva-templates/${uid}`,
      {
        headers: {
          "X-Api-Key": `${process.env.REACT_APP_API_KEY}`,
        },
      }
    );
    const { data, code } = await response.json();
    setDetailTemplate({ data, code });
  }, [uid]);

  useEffect(() => {
    getDetailTemplate();
  }, [getDetailTemplate]);

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  useEffect(() => {
    if (categoryRecomendation && !dataRecomendation) {
      const filterRecomendation = {
        category: categoryRecomendation,
        page: 1,
        per_page: 4,
      };
      const RecomendationList = async () => {
        setShowSkeleton(true);
        const { data, code } = await getTemplateList(filterRecomendation);
        setDataRecomendation({ data, code });
        setShowSkeleton(false);
      };
      RecomendationList();
    }
  }, [detailTemplate, dataRecomendation, categoryRecomendation]);

  return (
    <>
      {!detailTemplate ? (
        <Spinner full />
      ) : (
        <div className="detail-layout max-h-1818">
          {!detailTemplate?.data || detailTemplate?.code !== 200 ? (
            <Flex
              vertical
              align="center"
              justify="center"
              {...(width < 1200
                ? { className: "h-screen px-15" }
                : { className: "h-screen px-30" })}
              style={{ background: "#FFFFFF" }}
            >
              {emptyTemplateDetail()}
            </Flex>
          ) : (
            <>
              <div className="w-full" style={{ background: "white" }}>
                <div className="max-w-1512">
                  <Navbar />
                </div>
                <Divider className="my-0" />
              </div>
              <div
                className={`pt-30 pb-20 ${
                  width < 576
                    ? "px-5"
                    : width < 767
                    ? "pl-5"
                    : width < 992
                    ? "pl-10"
                    : "pl-10"
                } max-w-1512`}
              >
                <CardDetail
                  detailTemplate={detailTemplate?.data?.record}
                  width={width}
                />
              </div>
              <div className="flex" style={{ background: "white" }}>
                <div
                  className={`flex pt-30 ${
                    width < 576
                      ? "px-4 pb-40"
                      : width < 768
                      ? "px-4 pb-30"
                      : width < 1200
                      ? "px-15 pb-30"
                      : "px-20 pb-35"
                  } max-w-1512`}
                >
                  <CardDetailTemplate
                    detailTemplate={detailTemplate?.data?.record}
                    width={width}
                  />
                </div>
              </div>
              <div style={{ background: "#F9F9F9" }}>
                <div
                  className={`pt-20 ${
                    width < 576
                      ? "px-4 pb-40"
                      : width < 768
                      ? "px-4 pb-30"
                      : width < 1200
                      ? "px-15 pb-30"
                      : "px-20 pb-35"
                  } max-w-1512`}
                >
                  <Flex vertical gap={52}>
                    <Text className="font-semibold text-2-xl family-sans">
                      Other templates may you like
                    </Text>
                    <CardRecomendation
                      ListTemplate={dataRecomendation}
                      showSkeleton={showSkeleton}
                      width={width}
                    />
                  </Flex>
                </div>
              </div>
              <div className="w-full" style={{ backgroundColor: "#1F1F1F" }}>
                <div
                  className={`${
                    isLargeScreen ? "px-20 pt-20 pb-15" : "px-5 py-15"
                  }  max-w-1512`}
                  style={{ backgroundColor: "#1F1F1F" }}
                >
                  <FooterPage />
                </div>
                <Flex justify="center" style={{ backgroundColor: "#00000073" }}>
                  <p className="text-xs" style={{ color: "#7A7A7A" }}>
                    © Copyright 2023 PT Aktiva Kreasi Investama | All Rights
                    Reserved
                  </p>
                </Flex>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default DetailPage;
