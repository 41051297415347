import { configureStore } from "@reduxjs/toolkit";
import userData from "./slices/user";
import templateCategory from "./slices/templateCategory";
import manageUsers from "./slices/manageUsers";

export default configureStore({
  reducer: {
    userData,
    templateCategory,
    manageUsers,
  },
});
