import { Image, Modal } from "antd";

export const ModalPreview = ({
  data,
  openModalPreview,
  setOpenModalPreview,
}) => {
  return (
    <Modal
      title={data?.name}
      centered
      open={openModalPreview}
      onCancel={() => setOpenModalPreview(false)}
      footer={false}
      className="modal-image"
    >
      <div className="modal-image-container">
        <Image src={data?.screenshot} preview={false} />
      </div>
    </Modal>
  );
};
