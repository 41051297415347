import { Link } from "react-router-dom";
import AktivaDark from "assets/images/aktiva-dark.png";
import AktivaLogo from "assets/images/aktiva.png";
import { Button, Dropdown, Layout, Col, Grid, Row } from "antd";
import {
  ArrowLeftOutlined,
  PlusCircleFilled,
  UserOutlined,
} from "@ant-design/icons";
import ArrowDownSVG from "assets/images/ArrowDown.svg";
import MailSVG from "assets/images/Mail.svg";
import logoDark from "assets/images/aktiva-dark.png";
import { TemplateChanges } from "../Notification";
import { MenuReguler, MenuHamburger } from "../Menu/Menu";
import "./navbar.less";
import { UserDropdown } from "components/Dropdown";
import { useSelector } from "react-redux";

const { Header } = Layout;
const { useBreakpoint } = Grid;

export const NavbarEditor = ({
  dataTemplate,
  exportHtml,
  templateExported,
  templateChanges,
  CreatePage,
  EditPage,
}) => {
  const user = useSelector(({ userData }) => userData.value);
  const items = [
    {
      key: "1",
      label: (
        <div className="flex justify-between gap-x-3" onClick={exportHtml}>
          <img src={ArrowDownSVG} alt="" />
          <div className="flex flex-col gap-y-1">
            <span className="font-bold text-base">Download HTML</span>
            <span>
              Download gratis email template berformat <br /> zip beserta HTML
              dan gambar.
            </span>
          </div>
        </div>
      ),
    },
    {
      type: "divider",
      style: { margin: "20px 0" },
    },
    {
      key: "2",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.aktiva.co.id/mail-campaign/"
        >
          <div className="flex justify-between gap-x-3">
            <img src={MailSVG} alt="" />
            <div className="flex flex-col gap-y-1">
              <span className="font-bold text-base">Kirim ke Customer</span>
              <span>
                Kirim email template tersebut ke customer
                <br /> anda dengan Aktiva Mail Campaign.
              </span>
            </div>
          </div>
        </a>
      ),
    },
  ];

  const BtnExport = () => {
    return (
      <Dropdown
        menu={{
          items,
          style: { padding: "24px 20px" },
        }}
        arrow
        placement="bottomRight"
        trigger={["click"]}
      >
        <Button type="primary">
          <PlusCircleFilled />
          <span className="font-semibold" style={{ marginInlineStart: 4 }}>
            Export
          </span>
        </Button>
      </Dropdown>
    );
  };

  return (
    <>
      <Header
        className="px-6 flex flex-col justify-center"
        style={{ backgroundColor: "#1F1F1F" }}
      >
        <div className="flex justify-between">
          <div className="flex items-center gap-x-3">
            <img src={AktivaDark} alt="Logo Aktiva Dark" />
            {!(CreatePage || EditPage) && (
              <span className="text-white font-semibold">
                {dataTemplate ? dataTemplate.name : ""}
              </span>
            )}
          </div>
          <div className="flex justify-between items-center gap-x-3">
            {!(CreatePage || EditPage) ? (
              templateExported || !templateChanges ? (
                <>
                  <Link
                    to={
                      dataTemplate
                        ? `/templates/${dataTemplate.uid}`
                        : "/manage/templates"
                    }
                  >
                    <Button className="btn-back-editor text-success">
                      <ArrowLeftOutlined />
                      <span style={{ marginInlineStart: 4 }}>
                        Back to templates
                      </span>
                    </Button>
                  </Link>
                  <BtnExport />
                </>
              ) : (
                <>
                  <Button
                    className="btn-back-editor text-success"
                    onClick={() =>
                      TemplateChanges({ dataTemplate: dataTemplate })
                    }
                  >
                    <ArrowLeftOutlined />
                    <span style={{ marginInlineStart: 4 }}>
                      Back to templates
                    </span>
                  </Button>
                  <BtnExport />
                </>
              )
            ) : (
              <>
                <Button
                  icon={<UserOutlined className="text-base" />}
                  type="link"
                  className="text-white"
                >
                  {user.email}
                </Button>
              </>
            )}
          </div>
        </div>
      </Header>
    </>
  );
};

export const Navbar = () => {
  const screens = useBreakpoint();
  const isMediumScreen = screens.md;
  const isExtraLargeScreen = screens.xl;

  return (
    <Header
      className={`flex items-center justify-between bg-white h-navbar max-w-1380 ${
        !isExtraLargeScreen ? "px-5" : "px-10"
      }`}
    >
      <Col xl={8} lg={8} md={5} className="flex justify-start">
        <img src={AktivaLogo} alt="Logo Aktiva" width={150} height={45} />
      </Col>
      <Col xl={16} lg={16} md={19}>
        <Row className="py-2 gap-x-4" justify={"end"}>
          {isMediumScreen ? <MenuReguler /> : <MenuHamburger />}
        </Row>
      </Col>
    </Header>
  );
};

export const ManageNavbar = ({ userData }) => {
  return (
    <div className="flex justify-between">
      <div className="flex items-center">
        <img src={logoDark} alt="logo" />
      </div>
      <div className="flex justify-between items-center">
        <div className="dropdown-user">
          <Dropdown
            overlay={<UserDropdown userData={userData} />}
            placement="bottomRight"
            trigger={["click"]}
            className="notification-icon"
            overlayStyle={{ paddingTop: 16 }}
          >
            <Button
              icon={<UserOutlined className="text-base" />}
              type="link"
              className="user-button"
            >
              {userData.user_metadata.full_name}
            </Button>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};
