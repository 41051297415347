import { Button, Card, Flex, Image, Typography } from "antd";
import Scratch from "assets/images/scratch.svg";
import { ICPlusOutlined } from "components/list/ListIcon";
import { useCallback, useEffect, useState } from "react";
import { CardTemplate } from "../templatesCard";
import { getTemplateList } from "api";

export const ChooseTemplate = ({ next }) => {
  const [activeTabKey, setActiveTabKey] = useState("blank");
  const [dataTemplate, setDataTemplate] = useState(null);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [filterMyTemplate, setFilterMyTemplate] = useState({
    page: 1,
    per_page: 8,
  });
  const [currentPage, setCurrentPage] = useState(filterMyTemplate.page);

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const MyTemplateList = useCallback(async () => {
    setShowSkeleton(true);
    const { data, code } = await getTemplateList(filterMyTemplate);
    setDataTemplate({ data, code });
    setShowSkeleton(false);
  }, [filterMyTemplate]);

  useEffect(() => {
    MyTemplateList();
  }, [MyTemplateList]);

  const onTabChange = (key) => {
    setActiveTabKey(key);
  };

  const changePage = (page) => {
    setCurrentPage(page);
    setFilterMyTemplate({
      ...filterMyTemplate,
      page,
    });
  };

  const TabList = [
    {
      key: "blank",
      label: "Start from blank",
    },
    {
      key: "choose",
      label: "Choose template",
    },
  ];

  const ContentTabList = {
    blank: (
      <Flex justify="center">
        <Card className="max-w-280">
          <Flex justify="center">
            <Image src={Scratch} preview={false} />
          </Flex>
          <Flex vertical gap={8} align="center" className="mt-8">
            <Typography.Text className="text-base">
              Design from scratch
            </Typography.Text>
            <Button icon={<ICPlusOutlined />} type="primary" onClick={next}>
              Start Design
            </Button>
          </Flex>
        </Card>
      </Flex>
    ),
    choose: (
      <Card className="card-choose" bordered={false}>
        <CardTemplate
          ListTemplate={dataTemplate}
          showSkeleton={showSkeleton}
          width={width}
          filterTemplate={filterMyTemplate}
          newTemplate={true}
          next={next}
          changePage={changePage}
          currentPage={currentPage}
        />
      </Card>
    ),
  };

  return (
    <Card
      tabList={TabList}
      activeTabKey={activeTabKey}
      onTabChange={onTabChange}
      tabProps={{
        size: "middle",
        centered: true,
      }}
      className="card-blank"
    >
      {ContentTabList[activeTabKey]}
    </Card>
  );
};
