import { message } from "antd";
import Cookies from "js-cookie";
import JSZip from "jszip";
import { signInPublicURL } from "supabase";

export const getSiteMode = () => {
  const { pathname: path } = window.location;
  if (path.includes("/admin")) return "admin";
  return "user";
};

export const getLoginStatus = () => {
  const inCookie = Cookies.get("is-login");
  const inStorage = localStorage.getItem("is-login");
  return inCookie || inStorage === 1;
};

export const isFunction = (fn) => typeof fn === "function";

export const runFunction = (fn) => {
  if (typeof fn === "function") fn();
};

export const formatNumberWithDot = (number) => {
  return number?.toLocaleString().replace(/,/g, ".");
};

export const getStartPagin = (page, length) => {
  if (
    (!page && !length) ||
    (typeof page === "number" && typeof length === "number")
  )
    return 0;
  if (page === 0) return page;
  return page - 1 + length;
};

export const convertIDR = (number = 0) => {
  if (typeof number !== "number") return "Rp data-number-invalid";
  return `Rp${number.toLocaleString("id-ID")}`;
};
export const setStorage = (name, value) => localStorage.setItem(name, value);
export const getStorage = (name) => localStorage.getItem(name);
export const removeStorage = (name = "" || []) => {
  if (typeof name === "object" && name.length > 0)
    name.map((n) => localStorage.removeItem(n));
  else localStorage.removeItem(name);
};
export const setCookie = (name, value, options = { path: "/" }) =>
  Cookies.set(name, value, { ...options });
export const getCookie = (name) => Cookies.get(name) || false;
export const removeCookie = (name = "" || []) => {
  if (typeof name === "object" && name.length > 0)
    name.map((n) => Cookies.remove(n));
  else Cookies.remove(name);
};

export const validateRepeatPassword = ({ getFieldValue }) => ({
  validator(_, value) {
    if (!value || getFieldValue("password") === value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Password tidak sesuai"));
  },
});

export const BanCharacters = /^[a-zA-Z !@#$%^&*()_+{}[\]:;<>,.?~\\/-]*$/;

export const requiredRules = {
  required: true,
  message: "Harap diisi terlebih dahulu",
};

export const requiredEmail = {
  required: true,
  message: "Alamat email wajib diisi",
};

export const numberRules = {
  pattern: new RegExp(/^[0-9]+$/),
  message: "Mohon masukkan angka!",
};

export const regexGroupName = /^[a-z0-9_]+$/;

export const regexDomain =
  /^(https?:\/\/)?[0-9a-z-_]*(\.[0-9a-z-_]+)*(\.[a-z]{2,4})+(\/[0-9a-z-_]*)*?\/?$/gim;

export const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export const regexPhone = /^[0|62]{1,3}\d{7,16}/gm;

export const regexGroupMember =
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}|(https?:\/\/)?(?:@[0-9a-z-]+(?:\.[0-9a-z-]+)*(?:\.[a-z]{2,4})+(?:\/[0-9a-z-]*)*?\/?|any)$/gim;

export const regexPassword =
  /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}/[\]|\\:;"'<>,.?/_₹]).{6,}$/;

export const regexChangePasswordSMTP =
  /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}/[\]|\\:;"'<>,.?/_₹]).{10,}$/;

export const getSessionToken = () => {
  return window.sessionStorage.getItem("token_builder");
};

export const setCookieToken = (value) => {
  return setCookie("token_builder", value);
};

export const getCookieToken = () => {
  return getCookie("token_builder");
};

export const getAuthToken = () => {
  return getCookie("sb-access-token");
};

export const onEnter = (e, callback) => {
  if (!e || !e.keyCode || !e.key) return "";
  if ((e.keyCode === 13 || e.key === "Enter") && isFunction(callback))
    callback();
};

export const setLogin = (data, path, user) => {
  if (!data || !path) return false;
  if (user) setCookie("token_builder", data.session.access_token);
};

export const setLogout = () => {
  removeCookie("token_builder");
  setTimeout(() => {
    window.location.href = "/manage/login";
  }, 150);
};

export function secondsToTime(e) {
  const m = Math.floor((e % 3600) / 60)
      .toString()
      .padStart(2, "0"),
    s = Math.floor(e % 60)
      .toString()
      .padStart(2, "0");

  return `${m} menit ${s} detik`;
}

export const gatherFormData = (form, callback) => {
  if (form && form.getFieldsValue && form.getFieldsError) {
    form.submit();
    let errAmount = [];
    setTimeout(() => {
      form.getFieldsError().map((e) => e.errors.length && errAmount.push(e));
      const values = form.getFieldsValue();
      if (errAmount.length < 1 && isFunction(callback)) return callback(values);
    }, 100);
    return false;
  }
  return false;
};

export const maskNPWP = (data = "") => {
  if (data === "" || data === null) return "-";
  return `${data.substring(0, 2)}.${data.substring(2, 5)}.${data.substring(
    5,
    8
  )}.${data.substring(8, 9)}-${data.substring(9, 12)}.${data.substring(
    12,
    15
  )}`;
};

export const inAdmin = () => {
  return window.location.href.includes("/admin");
};

export const capitalizeFirstLetter = (word = "") => {
  return word && word.charAt(0).toUpperCase() + word.slice(1);
};

export const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener, noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const splitDate = (date) => {
  const dateSplit = date.split(" ");
  const dayDate = dateSplit[0];
  const time = dateSplit[1];
  return { date: dayDate, time };
};

export const base64StringToBlob = (base64String) => {
  const byteCharacters = atob(base64String.split(",")[1]);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: "image/png" });
};

export const unzipFile = async (zipFile) => {
  const jszip = new JSZip();
  const unzipped = await jszip.loadAsync(zipFile);
  const jsonFile = Object.keys(unzipped.files).find((filename) =>
    filename.endsWith(".json")
  );
  if (jsonFile) {
    let fileContent = await unzipped.files[jsonFile].async("string");
    return fileContent;
  } else {
    message.error(
      "No JSON template format found, please upload with JSON template"
    );
    return;
  }
};

export const unzipImage = async (zipFile) => {
  const jszip = new JSZip();
  const unzipped = await jszip.loadAsync(zipFile);
  const imageFile = Object.keys(unzipped.files).find((filename) =>
    filename.match(/\.(jpg|jpeg|png)$/i)
  );

  if (imageFile) {
    let fileContent = await unzipped.files[imageFile].async("blob");
    const fileType = fileContent.type || `${imageFile.split(".").pop()}`;
    return {
      name: imageFile,
      size: fileContent.size,
      type: fileType,
      content: fileContent,
    };
  }
};

export const exportTemplateZip = async (data) => {
  const zip = new JSZip();
  let screenshot, dataJson;
  screenshot = data.id
    ? await signInPublicURL({
        from: "template-images",
        file: data.screenshot,
      })
    : data.screenshot;
  dataJson = data.id ? data.contentJSON : data.json;

  zip.file(`${data?.name}-template.json`, JSON.stringify(dataJson, null, 2));

  const response = await fetch(screenshot);
  const screenshotBlob = await response.blob();

  zip.file(`${data?.name}-screenshot.png`, screenshotBlob);

  zip.generateAsync({ type: "blob" }).then((blob) => {
    const zipLink = document.createElement("a");
    const zipUrl = URL.createObjectURL(blob);

    zipLink.href = zipUrl;
    zipLink.download = `${data?.name}-template.zip`;

    document.body.appendChild(zipLink);
    zipLink.click();
    document.body.removeChild(zipLink);
    URL.revokeObjectURL(zipUrl);
  });
};

export const FaultError = async () => {
  const proxyUrl =
    "/wp-content/uploads/2024/07/blank-email-template-placeholder.svg";
  // let imageFile;

  const response = await fetch(proxyUrl);
  // const blob = await response.blob();
  // imageFile = new File([blob], "blank-email-template-placeholder.svg", {
  //   type: "image/jpeg",
  // });

  return response;
};
