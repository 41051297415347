import { Typography, Card, Radio, Flex, Col, Row, Select, Input } from "antd";
import logoDark from "../assets/images/aktiva-dark.png";
import logo from "../assets/images/aktiva.png";
import { AppstoreOutlined, MenuOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";

const { Option } = Select;
const { Search } = Input;

const { Title, Text } = Typography;

export const LogoAktiva = ({ className }) => {
  return (
    <div className={className}>
      <img src={logo} alt="logo" />
    </div>
  );
};

export const LogoAktivaDark = ({ className }) => {
  return (
    <div className={className}>
      <img src={logoDark} alt="logo" />
    </div>
  );
};

export const TitleAndParagraph = ({
  level,
  classNameT,
  classNameP,
  childrenP,
  childrenT,
}) => {
  return (
    <>
      <Title level={level} className={`${classNameT} title-form`}>
        {childrenT}
      </Title>
      <p className={`text-secondary ${classNameP}`}>{childrenP}</p>
    </>
  );
};

export const TitleParagraph = ({
  title = "",
  desc = "",
  descClass,
  className,
}) => {
  const titleClass = className || "mb-1";
  return (
    <>
      <h2 className={`text-3xl font-medium ${titleClass}`}>{title}</h2>
      <p className={`text-secondary ${descClass}`}>{desc}</p>
    </>
  );
};

export const FilterComponent = ({ propsFilter, propsValue, galleryPage }) => {
  const { changePerPage, changeCategory, changeSearchQuery, handleSearch } =
    propsFilter;
  const {
    inputValue,
    searchValue,
    CategoryData,
    currentPerPage,
    currentCategory,
    dataTemplate,
  } = propsValue;
  const recordLengthState = dataTemplate?.data
    ? dataTemplate?.data?.records
      ? dataTemplate?.data?.records.length === 0
      : dataTemplate?.data.length === 0
    : null;
  const [categoryOptions, setCategoryOptions] = useState([]);
  const searchInputRef = useRef(null);
  const noDataCategory =
    recordLengthState && currentCategory === "" && searchValue === "";
  const noData = recordLengthState && searchValue === "";
  const dataNotFound = recordLengthState && searchValue !== "";

  useEffect(() => {
    if (Array.isArray(CategoryData)) {
      const category = CategoryData.map((category) => ({
        value: category.id,
        label: category.name,
      }));
      setCategoryOptions(category);
    }
  }, [CategoryData]);

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [inputValue]);

  const SelectPage = () => {
    return (
      <Select
        className="mr-3"
        onChange={changePerPage}
        value={currentPerPage}
        disabled={!dataTemplate || dataNotFound || noData}
      >
        <Option value="20">20 per page</Option>
        <Option value="40">40 per page</Option>
        <Option value="60">60 per page</Option>
        <Option value="80">80 per page</Option>
        <Option value="100">100 per page</Option>
      </Select>
    );
  };

  const SelectCategory = () => {
    return (
      <Flex gap={4}>
        <p className="flex items-center text-normal mt-0 mb-0">Category:</p>
        <Select
          onChange={(data) => changeCategory(data)}
          defaultValue=""
          value={currentCategory}
          className="mr-3"
          {...(!galleryPage
            ? {
                options: [
                  { value: "", label: "Semua Template" },
                  ...categoryOptions,
                ],
              }
            : {
                options: [
                  {
                    value: "",
                    label: "All Categories",
                  },
                  {
                    value: "Transactional",
                    label: "Transactional Email",
                  },
                  {
                    value: "Promotion",
                    label: "Promotion",
                  },
                  {
                    value: "Newsletter",
                    label: "Newsletter",
                  },
                  {
                    value: "Invitation",
                    label: "Invitation",
                  },
                  {
                    value: "Customer Feedback",
                    label: "Customer Feedback",
                  },
                ],
              })}
          disabled={
            !dataTemplate ||
            (dataTemplate && !CategoryData && !galleryPage) ||
            noDataCategory
          }
        />
      </Flex>
    );
  };

  const SearchFilter = () => {
    return (
      <>
        <Search
          placeholder="Cari template email.."
          enterButton
          onSearch={handleSearch}
          onChange={changeSearchQuery}
          value={inputValue}
          ref={searchInputRef}
          disabled={!dataTemplate || noData}
        />
      </>
    );
  };

  return (
    <>
      <Card className="card-filter">
        <Row>
          <Col span={10} className="flex items-center">
            <Text className="text-base font-medium">Filter</Text>
          </Col>
          <Col span={14}>
            <Flex>
              <SelectPage />
              <SelectCategory />
              <SearchFilter />
            </Flex>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export const RadioTable = ({ radioValue, setRadioValue, propsValue }) => {
  const { dataTemplate, searchValue } = propsValue;
  const recordLengthState = dataTemplate?.data
    ? dataTemplate?.data?.records
      ? dataTemplate?.data?.records.length === 0
      : dataTemplate?.data.length === 0
    : null;
  const noData = recordLengthState && searchValue === "";
  const dataNotFound = recordLengthState && searchValue !== "";
  return (
    <>
      <Radio.Group
        value={radioValue}
        onChange={(e) => setRadioValue(e.target.value)}
        buttonStyle="solid"
        disabled={!dataTemplate || dataNotFound || noData}
      >
        <Radio.Button value="gallery">
          <AppstoreOutlined /> Gallery View
        </Radio.Button>
        <Radio.Button value="list">
          <MenuOutlined /> List View
        </Radio.Button>
      </Radio.Group>
    </>
  );
};
