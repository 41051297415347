import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../redux/slices/user";
import { supabase } from "supabase";

const useGetUser = () => {
  const dispatch = useDispatch();
  const action = setUserData;
  const userData = useSelector(({ userData }) => userData.value);

  const doSet = useCallback(
    (data) => dispatch(action(data)),
    [dispatch, action]
  );

  useEffect(() => {
    const getUser = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (!userData) doSet(user);
    };
    if (!userData) getUser();
  }, [userData, doSet]);

  const refreshUser = async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    doSet(user);
  };

  return { refreshUser };
};

export default useGetUser;
