import { FormatPainterOutlined, LayoutOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Divider,
  Flex,
  Image,
  Skeleton,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { BtnCreate } from "components/AntButton";
import { emptyMainTemplate } from "components/Empty/EmptyComponent";
import {
  ICEditOutlined,
  ICEyeOutlined,
  ICPlusOutlined,
  ICUserOutlined,
} from "components/list/ListIcon";
import { useNavigate } from "react-router-dom";
import Scratch from "assets/images/scratch.svg";
import fault_error from "assets/images/fault_error.jpg";

const { Text } = Typography;

export const CardSummary = ({
  className,
  countTemplate,
  loadingTemplate,
  countUsers,
  loadingUsers,
}) => {
  return (
    <>
      <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={12}>
        <Card className={className} loading={loadingTemplate}>
          <Flex vertical>
            <Flex justify="space-between">
              <Text className="text-secondary font-medium">
                Templates Created
              </Text>
              <LayoutOutlined className="text-secondary" />
            </Flex>
            <Text className="text-3-xl font-medium">{countTemplate}</Text>
            <Text className="text-success font-medium">Email Template</Text>
            <Divider className="my-2" />
          </Flex>
        </Card>
      </Col>
      <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={12}>
        <Card className={className} loading={loadingUsers}>
          <Flex vertical>
            <Flex justify="space-between">
              <Text className="text-secondary font-medium">Total Member</Text>
              <ICUserOutlined className="text-secondary" />
            </Flex>
            <Text className="text-3-xl font-medium">{countUsers}</Text>
            <Text className="text-info font-medium">Member Registered</Text>
            <Divider className="my-2" />
          </Flex>
        </Card>
      </Col>
      <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
        <Card className={className}>
          <Flex vertical gap={5}>
            <Flex justify="space-between">
              <Text className="text-secondary font-medium">
                Create New Template
              </Text>
              <FormatPainterOutlined className="text-secondary" />
            </Flex>
            <Text className="text-info text-base">
              Design your own template
            </Text>
            <Flex>
              <BtnCreate mainState={true} />
            </Flex>
            <Divider className="mb-2 mt-0" />
          </Flex>
        </Card>
      </Col>
    </>
  );
};

export const ListTemplate = ({
  className,
  dataTemplate,
  loading,
  propsValue,
}) => {
  const navigate = useNavigate();
  const sliceSize = 1;
  const slicedData = dataTemplate ? dataTemplate.slice(0, sliceSize) : [];
  const { totalCards, publicUrls, mediumScreens } = propsValue;

  const ColumnList = [
    {
      title: "Preview",
      dataIndex: "screenshot",
      key: "Preview",
      className: "image-cell",
      width: 132,
      render: (_, record) => {
        return (
          <div
            style={{
              backgroundImage: `url(${
                publicUrls[record.id] ? publicUrls[record.id] : fault_error
              })`,
              backgroundSize: "cover",
              backgroundPosition: "top",
              height: 80,
            }}
          />
        );
      },
    },
    {
      title: "Template name",
      dataIndex: "name",
      key: "Template name",
    },
    {
      key: "actions",
      fixed: "right",
      render: (_, record) => (
        <Flex justify="flex-end" align="center" gap={4}>
          <Tooltip title="Edit template">
            <Button
              type="primary"
              className="text-success"
              size="medium"
              icon={<ICEditOutlined />}
              onClick={() =>
                navigate(`/manage/templates/edit/${record.id}`, {
                  state: record,
                })
              }
            />
          </Tooltip>
          <Tooltip title="See details">
            <Button
              className="text-success btn-list"
              icon={<ICEyeOutlined />}
              size="medium"
              onClick={() =>
                navigate(`/manage/templates/${record.id}`, {
                  state: record,
                })
              }
            />
          </Tooltip>
        </Flex>
      ),
    },
  ];

  const latestTemplate = (slicedData) => {
    const backgroundImageStyle = {
      backgroundImage: `url(${
        publicUrls[slicedData[0].id]
          ? publicUrls[slicedData[0].id]
          : fault_error
      })`,
      backgroundSize: "cover",
      backgroundPosition: "top",
      height: mediumScreens ? 320 : 80,
      ...(!mediumScreens && { width: 100 }),
    };
    return mediumScreens ? (
      <Flex vertical gap={30}>
        <Card bordered className="card-choose">
          <div style={backgroundImageStyle} />
          <div className="text-center mb-5">
            <p className="mb-2 mt-0 font-medium">{slicedData[0].name}</p>
            <Tag style={{ borderRadius: 12 }}>
              {slicedData[0].templateCategory === 1
                ? "Transactional"
                : slicedData[0].templateCategory === 2
                ? "Promotion"
                : slicedData[0].templateCategory === 3
                ? "Newsletter"
                : slicedData[0].templateCategory === 4
                ? "Invitation"
                : "Customer Feedback"}
            </Tag>
          </div>
        </Card>
        <Text
          className="text-success text-right mb-4"
          onClick={() =>
            navigate(`/manage/templates/edit/${slicedData[0].id}`, {
              state: slicedData[0],
            })
          }
          style={{ cursor: "pointer" }}
        >
          Continue Edit
        </Text>
      </Flex>
    ) : (
      <div className="px-4">
        <Flex gap={32}>
          <div style={backgroundImageStyle} />
          <Flex vertical justify="center" align="flex-start">
            <p className="mb-1 mt-0 font-medium">{slicedData[0].name}</p>
            <Tag style={{ borderRadius: 12 }}>
              {slicedData[0].templateCategory === 1
                ? "Transactional"
                : slicedData[0].templateCategory === 2
                ? "Promotion"
                : slicedData[0].templateCategory === 3
                ? "Newsletter"
                : slicedData[0].templateCategory === 4
                ? "Invitation"
                : "Customer Feedback"}
            </Tag>
          </Flex>
        </Flex>
        <Divider className="my-0 mt-2" />
        <Flex justify="flex-end">
          <Text
            className="text-success text-right mt-7"
            onClick={() =>
              navigate(`/manage/templates/edit/${slicedData[0].id}`, {
                state: slicedData[0],
              })
            }
            style={{ cursor: "pointer" }}
          >
            Continue Edit
          </Text>
        </Flex>
      </div>
    );
  };

  const latestTemplateEmpty = mediumScreens ? (
    <Card className="blank-latest-medium text-center">
      <Flex justify="center">
        <Image src={Scratch} preview={false} />
      </Flex>
      <Flex vertical align="center" className="mt-8">
        <Typography.Text className="text-base">
          No Template Available
        </Typography.Text>
        <Typography.Text className="text-secondary">
          Create & design your template
        </Typography.Text>
        <Button
          icon={<ICPlusOutlined />}
          type="primary"
          className="mt-2"
          onClick={() => navigate("/manage/templates/create")}
        >
          Start Design
        </Button>
      </Flex>
    </Card>
  ) : (
    <Card className="blank-latest">
      <Flex gap={32} align="center">
        <Col span={6} className="p-0">
          <Image src={Scratch} preview={false} height={108} width={140} />
        </Col>
        <Col span={18} className="p-0">
          <Flex vertical justify="center" align="center">
            <Typography.Text className="text-base text-center">
              No Template Available
            </Typography.Text>
            <Typography.Text className="text-secondary text-center">
              Create & design your template
            </Typography.Text>
            <Button
              icon={<ICPlusOutlined />}
              type="primary"
              className="mt-3"
              onClick={() => navigate("/manage/templates/create")}
            >
              Start Design
            </Button>
          </Flex>
        </Col>
      </Flex>
    </Card>
  );

  const renderState =
    slicedData.length > 0 ? latestTemplate(slicedData) : latestTemplateEmpty;

  return (
    <>
      {!mediumScreens && (
        <Col sm={24} xs={24}>
          <Card title="Your Latest Template" className="latest-template mt-3">
            {loading || !dataTemplate ? <Skeleton active /> : renderState}
          </Card>
        </Col>
      )}
      <Col xxl={17} xl={17} lg={17} md={16} sm={24} xs={24}>
        <Card className={className}>
          <Flex justify="space-between" align="center" className="title-list">
            <Text className="text-base font-medium">Your Email Templates</Text>
            <Button
              {...(!(loading || !dataTemplate || totalCards) && {
                type: "primary",
              })}
              disabled={loading || !dataTemplate || totalCards}
              onClick={() => navigate("/manage/templates")}
            >
              See All Templates
            </Button>
          </Flex>
          <ConfigProvider
            renderEmpty={() => (
              <Col
                className="flex flex-col items-center justify-center"
                style={{ height: "45vh" }}
              >
                {emptyMainTemplate({
                  totalCards: totalCards,
                })}
              </Col>
            )}
          >
            {loading || !dataTemplate ? (
              <Skeleton active paragraph={{ rows: 5 }} />
            ) : (
              <Table
                columns={ColumnList}
                dataSource={dataTemplate}
                pagination={false}
              />
            )}
          </ConfigProvider>
        </Card>
      </Col>
      {mediumScreens && (
        <Col xxl={7} xl={7} lg={7} md={8}>
          <Card title="Your Latest Template" className="latest-template">
            {loading || !dataTemplate ? <Skeleton active /> : renderState}
          </Card>
        </Col>
      )}
    </>
  );
};
