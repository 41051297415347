import { Button, Flex, Table, Form, Grid, Skeleton } from "antd";
import { TableTitle } from "../tableTitle";
import { ICDeleteOutlined, ICEditOutlined } from "components/list/ListIcon";
import { useState } from "react";
import { ModalManageUsers } from "components/modal/ModalManageUsers";
import { HandleDeleteUsers } from "utils/handleFunction";

const { useBreakpoint } = Grid;

export const TableManageUsers = ({
  manageData,
  userData,
  callbackManageList,
  refreshUser,
  loading,
}) => {
  const [openModalUsers, setOpenModalUsers] = useState(false);
  const [editState, setEditState] = useState(false);
  const [form] = Form.useForm();
  const screen = useBreakpoint();
  const mediumScreen = screen.md;
  const smallScreen = screen.sm;
  const xSmallScreen = screen.xs;
  const userRole = userData.user_metadata.role !== "service_role";
  const emailData = userData.email;

  const handleCloseModalUsers = () => {
    setOpenModalUsers(false);
    setEditState(false);
    form.resetFields();
  };

  const columnTableUsers = [
    {
      title: "Full Name",
      dataIndex: "full_name",
      width: 400,
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 322,
      render: (email, record) => {
        return record.email_change ? record.email_change : email;
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      width: 322,
      render: (role) => {
        return role === "service_role" ? "Super User" : "User";
      },
    },
    {
      width: 132,
      render: (record) => {
        return (
          <Flex gap={4}>
            <Button
              icon={<ICEditOutlined />}
              size="medium"
              onClick={() => {
                form.setFieldsValue({
                  uidUser: record.id,
                  full_name: record.full_name,
                  email: record.email_change
                    ? record.email_change
                    : record.email,
                  role: record.role,
                });
                setOpenModalUsers(true);
                setEditState(true);
              }}
              disabled={userRole}
            />
            <Button
              icon={<ICDeleteOutlined />}
              danger
              size="medium"
              onClick={() => HandleDeleteUsers({ record, callbackManageList })}
              disabled={userRole || emailData === record.email}
            />
          </Flex>
        );
      },
    },
  ];

  return (
    <div className="flex flex-col gap-y-6">
      <TableTitle
        title="List User"
        description="Registered user on system"
        setOpenModalUsers={setOpenModalUsers}
        screen={screen}
        userRole={userRole}
      />
      {!manageData || loading ? (
        <Skeleton active />
      ) : (
        <Table
          columns={columnTableUsers}
          dataSource={manageData}
          {...(!mediumScreen && (smallScreen || xSmallScreen)
            ? { scroll: { x: 1024 } }
            : undefined)}
          loading={!manageData || loading}
        />
      )}
      <ModalManageUsers
        open={openModalUsers}
        title={editState ? "Edit User" : "Add New User"}
        editState={editState}
        close={handleCloseModalUsers}
        form={form}
        userRole={userRole}
        callbackManageList={callbackManageList}
        refreshUser={refreshUser}
        emailData={emailData}
      />
    </div>
  );
};
