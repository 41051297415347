import {
  Button,
  Flex,
  Input,
  Modal,
  Select,
  Typography,
  Upload,
  message,
} from "antd";
import {
  ICCheckCircleOutlined,
  ICUploadOutlined,
} from "components/list/ListIcon";
import { useEffect, useState } from "react";
import ModalConfirm from "./ModalConfirm";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { insertDataToDB, updateDataToDB, uploadToBucket } from "supabase";
import ModalWarning from "./ModalWarning";
import { unzipFile, unzipImage } from "utils/helpers";

export const ModalImport = ({
  openModalImport,
  setOpenModalImport,
  userData,
  callbackList,
}) => {
  const [nameValue, setNameValue] = useState("");
  const [categoryValue, setCategoryValue] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const CategoryData = useSelector(
    ({ templateCategory }) => templateCategory.value
  );
  const disableBtn =
    nameValue === "" || categoryValue === "" || fileList.length === 0;

  const handleCloseModal = () => {
    setNameValue("");
    setCategoryValue("");
    setFileList([]);
    setOpenModalImport(false);
  };

  useEffect(() => {
    if (Array.isArray(CategoryData)) {
      const category = CategoryData.map((category) => ({
        value: category.id,
        label: category.name,
      }));
      setCategoryOptions(category);
    }
  }, [CategoryData]);

  const props = {
    name: "file",
    accept: ".zip",
    fileList,
    beforeUpload: (file) => {
      const isZip =
        file.type === "application/zip" || file.name.endsWith(".zip");
      if (!isZip) {
        message.error("You can only upload ZIP files!");
        return Upload.LIST_IGNORE;
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        message.error("File must be smaller than 5MB!");
        return Upload.LIST_IGNORE;
      }
      setFileList([file]);
      return false;
    },
    onRemove: () => setFileList([]),
  };

  const handleImport = async () => {
    if (fileList.length === 0) {
      message.error("No file uploaded. Please upload a file.");
      return;
    }
    const file = fileList[0];
    const jsonFile = await unzipFile(file);
    const imageFile = await unzipImage(file);

    if (jsonFile) {
      setLoading(true);
      const parsedJSON = JSON.parse(jsonFile);
      const { data: ImportedData, status } = await insertDataToDB({
        from: "userTemplate",
        insert: {
          userId: userData.id,
          name: nameValue,
          templateCategory: categoryValue,
          contentJSON: parsedJSON,
          storage: "template-images",
        },
      });
      if (status === 201 && ImportedData[0]) {
        if (imageFile) {
          const importedId = ImportedData[0].id.toString();
          const correctedImageFile = new File(
            [imageFile.content],
            imageFile.name,
            { type: "image/jpeg" }
          );
          const path = `${importedId}/${imageFile.name}`;
          const { data, error } = await uploadToBucket({
            from: "template-images",
            path: path,
            file: correctedImageFile,
          });
          if (data) {
            const { data: UploadImage, error } = await updateDataToDB({
              from: "userTemplate",
              update: {
                screenshot: path,
              },
              eq: "id",
              value: ImportedData[0].id,
            });
            if (UploadImage) {
              setLoading(false);
              ModalConfirm({
                title: "Template imported successfully",
                customMessage: `Email template ${ImportedData[0].name} successfully imported in your account.`,
                width: 416,
                type: "primary",
                okText: "See Template",
                cancelText: "Back to List",
                icon: <ICCheckCircleOutlined />,
                className: "modal-import",
                onOk: () => navigate(`${ImportedData[0].id}`),
                onCancel: () => {
                  handleCloseModal();
                  callbackList();
                },
              });
            } else if (error) {
              setLoading(false);
              ModalWarning({
                title: "Failed update image file template",
                customMessage: (
                  <span>
                    Something wrong in the system. Please try again or contact
                    our Support Team.
                    <br /> {error}
                  </span>
                ),
                width: 416,
                okText: "Ok",
                type: "primary",
                onOk: () => handleCloseModal(),
              });
            }
          } else if (error) {
            setLoading(false);
            ModalWarning({
              title: "Failed import image file template",
              customMessage: (
                <span>
                  Something wrong in the system. Please try again or contact our
                  Support Team.
                  <br /> {error}
                </span>
              ),
              width: 416,
              okText: "Ok",
              type: "primary",
              onOk: () => handleCloseModal(),
            });
          }
        } else {
          setLoading(false);
          ModalConfirm({
            title: "Template imported successfully",
            customMessage: `Email template ${ImportedData[0].name} successfully imported in your account.`,
            width: 416,
            type: "primary",
            okText: "See Template",
            cancelText: "Back to List",
            icon: <ICCheckCircleOutlined />,
            className: "modal-import",
            onOk: () => navigate(`${ImportedData[0].id}`),
            onCancel: () => {
              handleCloseModal();
              callbackList();
            },
          });
        }
      } else {
        setLoading(false);
        ModalWarning({
          title: "Failed import email template",
          customMessage:
            "Something wrong in the system. Please try again or contact our Support Team.",
          width: 416,
          okText: "Ok",
          type: "primary",
          onOk: () => handleCloseModal(),
        });
      }
    }
  };

  return (
    <Modal
      title="Import Email Template"
      centered
      open={openModalImport}
      onCancel={handleCloseModal}
      okText="Start Import"
      okButtonProps={{
        disabled: disableBtn,
        type: disableBtn ? "default" : "primary",
      }}
      width={572}
      onOk={handleImport}
      afterClose={() => setFileList([])}
      confirmLoading={loading}
    >
      <Typography.Text>
        Import email template to the dashboard and start editing template. Use
        .zip format with JSON file inside from unlayer.
      </Typography.Text>
      <Flex vertical className="pt-4">
        <Typography.Text>Template Name</Typography.Text>
        <Input
          placeholder="Your template name"
          className="mt-1"
          value={nameValue}
          onChange={(e) => setNameValue(e.target.value)}
        />
        <Typography.Text className="pt-4">Template Category</Typography.Text>
        <Select
          onChange={(data) => setCategoryValue(data)}
          defaultValue=""
          className="mt-1"
          value={categoryValue}
          options={[
            { value: "", label: "Choose category" },
            ...categoryOptions,
          ]}
          disabled={!CategoryData}
        />
        <Typography.Text className="pt-4 pb-2">
          Upload template file
        </Typography.Text>
        <Upload {...props} maxCount={1}>
          <Button icon={<ICUploadOutlined />}>Click to Upload</Button>
        </Upload>
        <Typography.Text className="pt-4 text-secondary text-sm">
          Maximum file allowed is <span className="font-bold">5MB</span>
        </Typography.Text>
      </Flex>
    </Modal>
  );
};
