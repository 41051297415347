import { Route, Routes } from "react-router-dom";
import { lazy } from "react";
import MainPage from "./pages/MainPage";
import { CheckLogin } from "./utils/CheckLogin";
import { CheckLoginManage } from "./utils/CheckLogin";
import BaseLayout from "./pages/Layout";
import DetailPage from "./pages/DetailPage";
import EmailEditor from "./pages/EmailEditor";
import ProtectedRoutes from "utils/ProtectedRoutes";
import Dashboard from "pages/manage/dashboard/Dashboard";
import MainDashboard from "pages/manage/dashboard/main/MainDashboard";
import MyTemplate from "pages/manage/dashboard/template/MyTemplate";
import { AddMyTemplate } from "components/Card/Add/AddTemplate";
import { DetailTemplate } from "components/Card/Detail/DetailTemplate";
import TemplateGallery from "pages/manage/dashboard/gallery/TemplateGallery";
import ManageUser from "pages/manage/dashboard/user/ManageUser";
import Profile from "pages/manage/dashboard/profile/Profile";

const Login = lazy(() => import("./pages/manage/auth/login/Login.js"));
const ForgotPassword = lazy(() =>
  import("./pages/manage/auth/password/ForgetPassword.js")
);
const ChangePassword = lazy(() =>
  import("./pages/manage/auth/password/ChangePassword.js")
);

const Routing = ({ userData, token }) => {
  return (
    <Routes>
      <Route index element={<CheckLogin />} />
      <Route element={<BaseLayout />}>
        <Route path="templates" element={<MainPage />} />
        <Route path="templates/:uid" element={<DetailPage />} />
        <Route path="template/edit/:uid" element={<EmailEditor />} />
      </Route>
      <Route path="manage">
        <Route index element={<CheckLoginManage userData={userData} />} />
        <Route path="login" element={<Login userData={userData} />} />
        <Route path="forgetpass" element={<ForgotPassword />} />
        <Route path="changepassword" element={<ChangePassword />} />
        <Route
          element={
            <ProtectedRoutes userData={userData} token={token}>
              <Dashboard userData={userData} />
            </ProtectedRoutes>
          }
        >
          <Route path="main" element={<MainDashboard userData={userData} />} />
          <Route
            path="templates"
            element={<MyTemplate userData={userData} />}
          />
          <Route
            path="templates/:id"
            element={<DetailTemplate userData={userData} />}
          />
          <Route
            path="templates/create"
            element={<AddMyTemplate userData={userData} />}
          />
          <Route path="gallery" element={<TemplateGallery />} />
          <Route path="gallery/:uid" element={<DetailTemplate />} />
          <Route path="users" element={<ManageUser userData={userData} />} />
          <Route path="profile" element={<Profile />} />
        </Route>
        <Route path="templates/create/:id" element={<EmailEditor />} />
        <Route path="templates/edit/:id" element={<EmailEditor />} />
        <Route path="gallery/edit/:uid" element={<EmailEditor />} />
      </Route>
    </Routes>
  );
};

export default Routing;
