import { Flex, Layout } from "antd";
import "./dashboardheader.less";
import {
  BtnCancel,
  BtnCreate,
  BtnExportImport,
  BtnSave,
} from "components/AntButton";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { ModalImport } from "components/modal/ModalImport";

const { Header } = Layout;

const DashboardHeader = ({
  title,
  TemplateID,
  gallery,
  saveTemplate,
  loadingState,
  finishState,
  recordState,
  userData,
  callbackList
}) => {
  const Location = useLocation();
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalImport, setOpenModalImport] = useState(false);

  const TemplatePage = Location.pathname === "/manage/templates";
  const CreatePage = Location.pathname === "/manage/templates/create";
  const CreatePageEditor =
    Location.pathname === `/manage/templates/create/${TemplateID}`;
  const EditPageTemplate =
    Location.pathname === `/manage/templates/edit/${TemplateID}`;
  const EditPageGallery =
    Location.pathname === `/manage/gallery/edit/${TemplateID}`;

  return (
    <Header className="dashboard-header">
      <h4 className="text-xl">
        <Flex justify="space-between">
          {recordState ? recordState.name : title ? title : "Template Name"}
          {TemplatePage ? (
            <Flex gap={8}>
              <BtnExportImport
                Import={true}
                setOpenModalImport={setOpenModalImport}
              />
              <BtnCreate />
            </Flex>
          ) : CreatePage ? (
            <BtnCancel />
          ) : gallery ? (
            <BtnExportImport
              type="primary"
              Import={true}
              setOpenModalImport={setOpenModalImport}
              disable={true}
            />
          ) : undefined}
          {(CreatePageEditor || EditPageTemplate || EditPageGallery) && (
            <Flex gap={8}>
              <BtnCancel
                CreatePageEditor={CreatePageEditor}
                EditPage={EditPageTemplate || EditPageGallery ? true : false}
                openModalDelete={openModalDelete}
                setOpenModalDelete={setOpenModalDelete}
                TemplateID={TemplateID}
              />
              <BtnSave
                saveTemplate={saveTemplate}
                loadingState={loadingState}
                finishState={finishState}
              />
            </Flex>
          )}
        </Flex>
        <ModalImport
          openModalImport={openModalImport}
          setOpenModalImport={setOpenModalImport}
          userData={userData}
          callbackList={callbackList}
        />
      </h4>
    </Header>
  );
};

export default DashboardHeader;
