import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
const supabaseServiceRole = process.env.REACT_APP_SERVICE_ROLE;
export const supabase = createClient(supabaseUrl, supabaseKey);
export const supabaseAdmin = createClient(supabaseUrl, supabaseServiceRole, {
  auth: {
    autoRefreshToken: false,
    persistSession: false,
  },
});
const adminAuthClient = supabaseAdmin.auth.admin;

export const getURL = () => {
  let url =
    process.env.REACT_APP_FE_BASE_URL ?? // Set this to your site URL in production env.
    process.env.REACT_APP_VERCEL_URL ?? // Automatically set by Vercel.
    "http://localhost:3000/";
  url = url.startsWith("http") ? url : `https://${url}`;
  url = url.endsWith("/") ? url : `${url}/`;
  return url;
};

export const getSession = async () => {
  const session = await supabase.auth.getSession();
  return session;
};

// Handling DB requests
export const getDataFromDB = async ({
  from = "",
  select = "",
  eq = "",
  value = "",
  limit = "",
  offset = "",
  search = "",
  category = "",
  order = false,
  order_name = false,
  count = false,
}) => {
  const selectFields = Array.isArray(select) ? select.join(", ") : select;
  let query = supabase
    .from(from)
    .select(selectFields, { count: count ? "exact" : null });
  if (eq && value !== "") {
    query = query.eq(eq, value);
  }
  if (search !== "") {
    query = query.ilike("name", `%${search}%`);
  }
  if (category !== "") {
    query = query.eq("templateCategory", category);
  }
  if (limit && offset !== "") {
    query = query.range(offset, offset + limit - 1);
  }
  if (order) {
    query = query.order("created_at", order);
  }
  if (order_name) {
    query = query.order("full_name", order_name);
  }
  const getData = await query;
  return getData;
};

export const insertDataToDB = async ({ from = "", insert = {} }) => {
  const insertData = await supabase.from(from).insert(insert).select();
  return insertData;
};

export const updateDataToDB = async ({
  from = "",
  update = {},
  eq = "",
  value = "",
}) => {
  const updateData = await supabase
    .from(from)
    .update(update)
    .eq(eq, value)
    .select();
  return updateData;
};

export const deleteDataFromDB = async ({ from = "", eq = "", value = "" }) => {
  const deleteData = await supabase.from(from).delete().eq(eq, value);
  return deleteData;
};

// User API
export const createNewUser = async ({
  email = "",
  password = "",
  data = {},
  email_confirm = true,
  phone_confirm = true,
  role = "",
}) => {
  const postUser = await adminAuthClient.createUser({
    email: email,
    password: password,
    role: role,
    email_confirm: email_confirm,
    phone_confirm: phone_confirm,
    user_metadata: data,
  });
  return postUser;
};

export const updateUser = async ({
  email = "",
  password = "",
  data = {},
  uid,
  role = "",
}) => {
  const updatePayload = {
    email: email,
    role: role,
    user_metadata: data,
  };

  if (password !== "") {
    updatePayload.password = password;
  }

  const putUser = await adminAuthClient.updateUserById(uid, updatePayload);
  return putUser;
};

export const deleteUser = async ({ id }) => {
  const noUser = await adminAuthClient.deleteUser(id);
  return noUser;
};

// Storage
export const signInPublicURL = async ({ from = "", file = "" }) => {
  const { data } = supabase.storage.from(from).getPublicUrl(file);
  return data.publicUrl;
};

export const uploadToBucket = async ({ from = "", path = "", file = "" }) => {
  const uploadResponse = await supabase.storage.from(from).upload(path, file, {
    headers: {
      Authorization: `${supabaseServiceRole}`,
    },
  });
  return uploadResponse;
};
