import { Col, Divider, Flex, Grid } from "antd";
import useDocumentTitle from "../utils/useDocumentTitle";
import { CardMain } from "../components/Card/headerCard";
import { CardTemplate, CardFilter } from "../components/Card/templatesCard";
import { useCallback, useEffect, useState, useRef } from "react";
import { getTemplateList } from "../api";
import { Navbar } from "../components/Navbar/Navbar";
import { FooterPage } from "../components/Footer/Footer";

const { useBreakpoint } = Grid;

const MainPage = () => {
  const screen = useBreakpoint();
  useDocumentTitle("Template Page");
  const [dataTemplate, setDataTemplate] = useState(null);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [filterTemplate, setFilterTemplate] = useState({
    category: "",
    search: "",
    page: 1,
    per_page: 12,
  });
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(filterTemplate.page);
  const [currentCategoy, setcurrentCategoy] = useState(filterTemplate.category);
  const mainTemplateListRef = useRef(null);
  const isLargeScreen = screen.lg;

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const TemplateList = useCallback(async () => {
    setShowSkeleton(true);
    const { data, code } = await getTemplateList(filterTemplate);
    setDataTemplate({ data, code });
    setShowSkeleton(false);
  }, [filterTemplate]);

  useEffect(() => {
    TemplateList();
  }, [TemplateList]);

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const changeSearchQuery = (query) => {
    setCurrentPage(1);
    setFilterTemplate({
      ...filterTemplate,
      page: 1,
      search: query,
    });
  };

  const changePage = (page) => {
    setCurrentPage(page);
    setFilterTemplate({
      ...filterTemplate,
      page,
    });
  };

  const changeCategory = (categories) => {
    setcurrentCategoy(categories);
    setCurrentPage(1);
    setFilterTemplate({
      ...filterTemplate,
      category: categories,
      page: 1,
    });
  };

  const handleExploreTemplateClick = () => {
    mainTemplateListRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <>
      <div className="base-layout">
        <div className="max-w-1512">
          <Navbar />
        </div>
        <Divider className="my-0" />
        <div className="px-6 pt-25 max-w-1512">
          <CardMain
            width={width}
            handleExploreTemplateClick={handleExploreTemplateClick}
          />
        </div>
        <div
          ref={mainTemplateListRef}
          {...(width < 1200
            ? { className: "flex flex-col px-6 py-20 gap-y-5" }
            : { className: "flex flex-col px-20 py-20 gap-y-5" })}
          style={{ background: "#F9F9F9" }}
        >
          {width < 992 && (
            <CardFilter
              searchValue={searchValue}
              handleSearch={handleSearch}
              changeSearchQuery={changeSearchQuery}
              changeCategory={changeCategory}
              currentCategoy={currentCategoy}
              width={width}
            />
          )}

          <div className="flex max-w-1512">
            {width > 991 && (
              <Col
                {...(width < 1200
                  ? { className: "mr-5" }
                  : { className: "mr-10" })}
              >
                <div style={{ position: "sticky", top: 24 }}>
                  <CardFilter
                    searchValue={searchValue}
                    handleSearch={handleSearch}
                    changeSearchQuery={changeSearchQuery}
                    changeCategory={changeCategory}
                    currentCategoy={currentCategoy}
                    width={width}
                  />
                </div>
              </Col>
            )}
            <Col
              {...(dataTemplate?.code !== 200
                ? {
                    className:
                      "flex flex-col justify-center items-center w-full",
                  }
                : { className: "w-full" })}
            >
              <CardTemplate
                ListTemplate={dataTemplate}
                showSkeleton={showSkeleton}
                filterTemplate={filterTemplate}
                changePage={changePage}
                currentPage={currentPage}
                width={width}
              />
            </Col>
          </div>
        </div>
      </div>
      <div className="w-full" style={{ backgroundColor: "#1F1F1F" }}>
        <div
          className={`${
            isLargeScreen ? "px-20 pt-20 pb-15" : "px-5 py-15"
          }  max-w-1512`}
          style={{ backgroundColor: "#1F1F1F" }}
        >
          <FooterPage />
        </div>
        <Flex justify="center" style={{ backgroundColor: "#00000073" }}>
          <p className="text-xs" style={{ color: "#7A7A7A" }}>
            © Copyright 2023 PT Aktiva Kreasi Investama | All Rights Reserved
          </p>
        </Flex>
      </div>
    </>
  );
};

export default MainPage;
