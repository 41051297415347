import { useRef } from "react";
import EmailBuilder from "react-email-editor";
import { useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Spinner from "../components/Spinner/Spinner";
import useDocumentTitle from "../utils/useDocumentTitle";
import { NavbarEditor } from "../components/Navbar/Navbar";
import JSZip from "jszip";
import { base64StringToBlob } from "../utils/helpers";
import DashboardHeader from "components/dashboard-header/DashboardHeader";
import { getDataFromDB, updateDataToDB } from "supabase";
// import { Buffer } from "buffer";

const EmailEditor = () => {
  useDocumentTitle("Email Template Editor");
  const emailEditorRef = useRef(null);
  const { uid } = useParams();
  const { id } = useParams();
  const [detailTemplate, setDetailTemplate] = useState(false);
  const [emailEditorReady, setEmailEditorReady] = useState(false);
  const [templateChanges, setTemplateChanges] = useState(false);
  const [templateExported, setTemplateExported] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [finishState, setFinishState] = useState(false);

  const Location = useLocation();
  const getRecord = Location.state;

  const CreatePage = Location.pathname === `/manage/templates/create/${id}`;
  const EditPageTemplate = Location.pathname === `/manage/templates/edit/${id}`;
  const EditPageGallery = Location.pathname === `/manage/gallery/edit/${uid}`;

  const navbarHeight =
    EditPageTemplate || EditPageGallery || CreatePage ? 132 : 68;

  useEffect(() => {
    if (id) {
      const getDetailTemplate = async () => {
        const { data } = await getDataFromDB({
          from: "userTemplate",
          eq: "id",
          value: id,
        });
        setDetailTemplate({ data });
      };
      getDetailTemplate();
    } else if (uid) {
      const getDetailTemplate = async () => {
        const response = await fetch(
          `${process.env.REACT_APP_BE_BASE_URL}/aktiva-templates/${uid}?edit=true`,
          {
            headers: {
              "X-Api-Key": `${process.env.REACT_APP_API_KEY}`,
            },
          }
        );
        const { data } = await response.json();
        setDetailTemplate({ data });
      };
      getDetailTemplate();
    }
  }, [uid, id]);

  const dataRecord = uid
    ? detailTemplate?.data?.record
    : detailTemplate?.data?.length > 0
    ? detailTemplate.data[0]
    : null;
  const templateJson = uid ? dataRecord?.json : dataRecord?.contentJSON;

  const GetScreenshot = async () => {
    const response = await fetch(dataRecord?.screenshot);
    const imageBlob = await response.blob();
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(imageBlob);
    });
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (templateChanges && !templateExported) {
        event.returnValue = true;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [templateChanges, templateExported]);

  const exportHtml = async () => {
    const screenshot = await GetScreenshot();
    emailEditorRef.current?.editor?.exportHtml((data) => {
      const { html, design } = data;
      if (html && design && screenshot) {
        const zip = new JSZip();
        zip.file(`${dataRecord?.name}-template.html`, html);
        zip.file(
          `${dataRecord?.name}-template.json`,
          JSON.stringify(design, null, 2)
        );
        const screenshotBlob = base64StringToBlob(screenshot);
        zip.file(`${dataRecord?.name}-screenshot.png`, screenshotBlob);

        zip.generateAsync({ type: "blob" }).then((blob) => {
          const zipLink = document.createElement("a");
          const zipUrl = URL.createObjectURL(blob);

          zipLink.href = zipUrl;
          zipLink.download = `${dataRecord?.name}-template.zip`;

          document.body.appendChild(zipLink);
          zipLink.click();
          document.body.removeChild(zipLink);
          URL.revokeObjectURL(zipUrl);

          setTemplateExported(true);
          setTemplateChanges(false);
        });
      } else {
        window.alert(
          "File HTML Failed to Download. Please try again or contact the Support Team"
        );
        setTemplateExported(false);
        setTemplateChanges(false);
      }
    });
  };

  const saveTemplate = async () => {
    setLoadingState(true);
    const exportHtml = () =>
      new Promise((resolve) => {
        emailEditorRef.current?.editor?.exportHtml((data) => {
          resolve(data.design);
        });
      });

    const templateDesign = await exportHtml();

    // const exportImage = async (design) => {
    //   const encodedApiKey = Buffer.from(
    //     process.env.REACT_APP_UNLAYER_KEY
    //   ).toString("base64");
    //   const url = `${process.env.REACT_APP_UNLAYER_URL}/export/image`;
    //   const options = {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Basic ${encodedApiKey}`,
    //     },
    //     body: JSON.stringify({
    //       displayMode: "email",
    //       design: design,
    //     }),
    //   };

    //   try {
    //     const response = await fetch(url, options);
    //     const json = await response.json();
    //     return json.url;
    //   } catch (error) {
    //     console.error("error:", error);
    //     return null;
    //   }
    // };

    // const templateImage = await exportImage(templateDesign);

    // console.log(templateImage);

    if (getRecord.contentJSON || templateDesign) {
      const updateContentJSON = templateDesign
        ? templateDesign
        : getRecord.contentJSON;
      const { status } = await updateDataToDB({
        from: "userTemplate",
        update: {
          contentJSON: updateContentJSON,
        },
        eq: "id",
        value: getRecord.id,
      });

      if (status === 200) {
        setTimeout(() => setLoadingState(false), 1000);
        setTimeout(() => setFinishState(true), 1000);
        setTimeout(() => setFinishState(false), 2000);
      }
    } else {
      setLoadingState(false);
    }
  };

  const onLoad = () => {
    emailEditorRef.current?.editor?.loadDesign(templateJson);
  };

  const onReady = () => {
    setEmailEditorReady(true);
  };

  emailEditorRef.current?.editor?.addEventListener(
    "design:updated",
    function (data) {
      var changes = data.changes;
      if (data && changes) {
        setTemplateChanges(true);
      }
    }
  );

  return (
    <>
      {!detailTemplate ? (
        <Spinner full />
      ) : (
        <>
          {emailEditorReady && (
            <>
              <NavbarEditor
                dataTemplate={dataRecord}
                exportHtml={exportHtml}
                templateExported={templateExported}
                templateChanges={templateChanges}
                CreatePage={CreatePage ? true : false}
                EditPage={EditPageTemplate || EditPageGallery ? true : false}
              />
              {(CreatePage || EditPageTemplate || EditPageGallery) && (
                <DashboardHeader
                  recordState={getRecord}
                  title={dataRecord?.name}
                  TemplateID={id ? id : uid}
                  saveTemplate={saveTemplate}
                  loadingState={loadingState}
                  finishState={finishState}
                />
              )}
            </>
          )}
          <EmailBuilder
            ref={emailEditorRef}
            onLoad={onLoad}
            onReady={onReady}
            minHeight={
              emailEditorReady ? `calc(100vh - ${navbarHeight}px)` : "100vh"
            }
          />
        </>
      )}
    </>
  );
};

export default EmailEditor;
