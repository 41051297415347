import { Card, Col, Flex, Form, Image, Row } from "antd";
import useDocumentTitle from "utils/useDocumentTitle";
import blankUser from "assets/images/user_blank.svg";
import { AccountInformation } from "components/Card/Profile/ProfileComp";
import { BtnGroupProfile } from "components/AntButton";
import { useCallback, useState } from "react";
import { ModalManageUsers } from "components/modal/ModalManageUsers";
import { useDispatch, useSelector } from "react-redux";
import { ModalChangePassword } from "components/modal/ModalChange";
import useGetUser from "utils/hooks/useGetUser";
import { getDataFromDB } from "supabase";
import { setManageUsers } from "../../../../redux/slices/manageUsers";

const Profile = () => {
  useDocumentTitle("Profile");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [openModalManage, setOpenModalManage] = useState(false);
  const [openModalChange, setOpenModalChange] = useState(false);
  const [profileState, setProfileState] = useState(false);
  const user = useSelector(({ userData }) => userData.value);
  const { refreshUser } = useGetUser();

  const callbackManageList = useCallback(async () => {
    const { data } = await getDataFromDB({
      from: "profiles",
      select: "*",
      order_name: { ascending: true },
    });
    dispatch(setManageUsers(data));
  }, [dispatch]);

  const handleOpenModalUsers = () => {
    form.setFieldsValue({
      uidUser: user.id,
      full_name: user.user_metadata.full_name,
      email: user.new_email ? user.new_email : user.email,
      role: user.user_metadata.role,
    });
    setOpenModalManage(true);
    setProfileState(true);
  };
  const handleCloseModalUsers = () => {
    setOpenModalManage(false);
    setProfileState(false);
    form.resetFields();
  };

  const handleOpenModalChanges = () => {
    setOpenModalChange(true);
  };
  const handleCloseModalChanges = () => {
    setOpenModalChange(false);
    form.resetFields();
  };

  return (
    <Row gutter={[24, 24]} className="py-4 px-3 mx-0">
      <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
        <Card className="profile-pic">
          <Flex justify="center">
            <Image src={blankUser} preview={false} />
          </Flex>
        </Card>
      </Col>
      <Col xxl={16} xl={16} lg={16} md={16} sm={24} xs={24}>
        <Card title="Account Information" className="profile-pic">
          <Flex vertical gap={12}>
            <AccountInformation user={user} />
            <BtnGroupProfile
              handleOpenModalUsers={handleOpenModalUsers}
              handleOpenModalChanges={handleOpenModalChanges}
            />
            <ModalManageUsers
              open={openModalManage}
              close={handleCloseModalUsers}
              title="Edit Profile"
              form={form}
              profileState={profileState}
              refreshUser={refreshUser}
              callbackManageList={callbackManageList}
            />
            <ModalChangePassword
              title="Change Password"
              open={openModalChange}
              form={form}
              close={handleCloseModalChanges}
              refreshUser={refreshUser}
            />
          </Flex>
        </Card>
      </Col>
    </Row>
  );
};

export default Profile;
