import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTemplateCategory } from "../../redux/slices/templateCategory";
import { getDataFromDB } from "supabase";

const useGetTemplateCategory = () => {
  const dispatch = useDispatch();
  const action = setTemplateCategory;
  const CategoryData = useSelector(
    ({ templateCategory }) => templateCategory.value
  );

  const doSet = useCallback(
    (data) => dispatch(action(data)),
    [dispatch, action]
  );

  useEffect(() => {
    const getTemplateCategory = async () => {
      const { data } = await getDataFromDB({
        from: "templateCategory",
        select: "*",
      });
      if (!CategoryData) doSet(data);
    };
    if (!CategoryData) getTemplateCategory();
  }, [CategoryData, doSet]);
};

export default useGetTemplateCategory;
