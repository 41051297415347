import { PageHeader } from "@ant-design/pro-layout";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const DashboardPageHeader = ({ title = "", extra, templateIdentity }) => {
  const Location = useLocation();
  const EditPageTemplate =
    Location.pathname === `/manage/templates/${templateIdentity}`;
  const EditPageGallery =
    Location.pathname === `/manage/gallery/${templateIdentity}`;

  const navigate = useNavigate();
  const back = () => {
    if (EditPageTemplate) {
      navigate("/manage/templates");
    }
    if (EditPageGallery) {
      navigate("/manage/gallery");
    }
  };

  return (
    <PageHeader
      className="page-header bg-white"
      extra={extra}
      onBack={back}
      title={title}
    />
  );
};

export default DashboardPageHeader;
