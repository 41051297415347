import { Affix, Button, Menu } from "antd";
import { Link } from "react-router-dom";
import { ICUserOutlined, ICPoweroffOutlined } from "./list/ListIcon";
import { handleLogout } from "utils/handleFunction";

export const UserDropdown = ({ userData }) => {
  return (
    <>
      <Affix offsetTop={64} style={{ width: 250 }}>
        <div className="bg-white navbarUser">
          <div className="flex flex-col px-3 py-1 h-auto bg-white">
            <p className="mb-0 mt-1">{userData.user_metadata.full_name}</p>
            <p className="text-secondary mb-0 mt-0">
              {userData.new_email ? userData.new_email : userData.email}
            </p>
          </div>
          <Menu
            className="dropdownMenu"
            items={[
              {
                key: "profile",
                label: (
                  <Link to={"profile"}>
                    <p className="mb-0 mt-0">Profil Saya</p>
                  </Link>
                ),
                icon: <ICUserOutlined />,
              },
            ]}
            style={{ padding: 0 }}
          />
          <Button
            type="link"
            icon={<ICPoweroffOutlined className="text-xs" rotate={90} />}
            className="btn-logout text-danger"
            onClick={handleLogout}
          >
            Keluar
          </Button>
        </div>
      </Affix>
    </>
  );
};
