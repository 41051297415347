import { Card, Col, Row } from "antd";
import { TableManageUsers } from "components/Table/users/TableManageUsers";
import DashboardHeader from "components/dashboard-header/DashboardHeader";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setManageUsers } from "../../../../redux/slices/manageUsers";
import { getDataFromDB } from "supabase";
import useGetManageUsers from "utils/hooks/useGetManageUsers";
import useDocumentTitle from "utils/useDocumentTitle";
import useGetUser from "utils/hooks/useGetUser";

const ManageUser = ({ userData }) => {
  useDocumentTitle("Manage User");
  useGetManageUsers();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const manageData = useSelector(({ manageUsers }) => manageUsers.value);
  const { refreshUser } = useGetUser();

  const callbackManageList = useCallback(async () => {
    setLoading(true);
    const { data } = await getDataFromDB({
      from: "profiles",
      select: "*",
      order_name: { ascending: true },
    });
    dispatch(setManageUsers(data));
    setTimeout(setLoading(false), 1000);
  }, [dispatch]);

  return (
    <>
      <DashboardHeader title="Manage User" />
      <Row className="px-6 py-3">
        <Col span={24}>
          <Card className="card-users">
            <TableManageUsers
              manageData={manageData}
              userData={userData}
              callbackManageList={callbackManageList}
              refreshUser={refreshUser}
              loading={loading}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ManageUser;
