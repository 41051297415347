import { Row, Grid } from "antd";
import { CardSummary, ListTemplate } from "components/Card/Main/MainComponents";
import { useCallback, useEffect, useState } from "react";
import { getDataFromDB, signInPublicURL } from "supabase";
import useDocumentTitle from "utils/useDocumentTitle";

const { useBreakpoint } = Grid;

const MainDashboard = ({ userData }) => {
  useDocumentTitle("Dashboard");
  const [countTemplate, setCountTemplate] = useState(false);
  const [countUsers, setcountUsers] = useState(false);
  const [dataTemplate, setDataTemplate] = useState(null);
  const [loadingTemplate, setLoadingTemplate] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [publicUrls, setPublicUrls] = useState({});
  const Screens = useBreakpoint();
  const mediumScreens = Screens.md;

  const getCountTemplate = useCallback(async () => {
    setLoadingTemplate(true);
    const { count } = await getDataFromDB({
      from: "userTemplate",
      select: "count",
      count: true,
      eq: "userId",
      value: userData.id,
    });
    setCountTemplate(count);
    setLoadingTemplate(false);
  }, [userData.id]);

  useEffect(() => {
    getCountTemplate();
  }, [getCountTemplate]);

  const getCountUsers = useCallback(async () => {
    setLoadingUsers(true);
    const { count } = await getDataFromDB({
      from: "profiles",
      select: "count",
      count: true,
    });
    setcountUsers(count);
    setLoadingUsers(false);
  }, []);

  useEffect(() => {
    getCountUsers();
  }, [getCountUsers]);

  const MyTemplateList = useCallback(async () => {
    setShowSkeleton(true);
    const responseData = await getDataFromDB({
      from: "userTemplate",
      select: "*",
      eq: "userId",
      value: userData.id,
      limit: 5,
      offset: 0,
      order: { ascending: false },
    });
    setDataTemplate(responseData);
    setShowSkeleton(false);
  }, [userData.id]);

  useEffect(() => {
    MyTemplateList();
  }, [MyTemplateList]);

  const recordRenderState = dataTemplate?.data;
  const recordStatusCode = dataTemplate?.status;
  const totalCards = dataTemplate?.data
    ? dataTemplate?.data.length === 0
    : null;

  useEffect(() => {
    if (recordRenderState && recordRenderState.length > 0) {
      const fetchPublicUrls = async () => {
        const urls = {};

        for (const card of recordRenderState) {
          const url = await signInPublicURL({
            from: "template-images",
            file: card.screenshot,
          });

          if (!url.endsWith("/null")) {
            urls[card.id] = url;
          }
        }
        setPublicUrls(urls);
      };
      fetchPublicUrls();
    }
  }, [recordRenderState]);

  const propsValue = {
    recordRenderState,
    recordStatusCode,
    totalCards,
    publicUrls,
    mediumScreens,
  };

  return (
    <Row
      {...(mediumScreens ? { gutter: [24, 24] } : { gutter: [24, 12] })}
      className="py-4 px-3 mx-0"
    >
      <CardSummary
        className="summary"
        countTemplate={countTemplate}
        loadingTemplate={loadingTemplate}
        countUsers={countUsers}
        loadingUsers={loadingUsers}
      />
      <ListTemplate
        className="list-email"
        dataTemplate={dataTemplate?.data}
        loading={showSkeleton}
        propsValue={propsValue}
      />
    </Row>
  );
};

export default MainDashboard;
