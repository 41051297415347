import { Modal } from "antd";

const { warning } = Modal;

const ModalWarning = (props) => {
  const {
    title,
    width = 416,
    message,
    customMessage,
    okButtonProps,
    type,
    onOk,
    okText,
  } = props;
  const messageModal = customMessage || <p className="mb-0">{message}</p>;

  return warning({
    ...props,
    title: title,
    width: width,
    content: messageModal,
    centered: true,
    okText: okText || "Oke",
    okButtonProps: { ...okButtonProps, type: type, className: "btn-modal" },
    autoFocusButton: null,
    onOk: () => {
      onOk();
      Modal.destroyAll();
    },
  });
};

export default ModalWarning;
